import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import FooterCopyRight from "../componets/FooterCopyRight";
import LandingNavbar from "../componets/MainLayout/LandingNavbar";
import Navbar from "../componets/Navbar";
import Sidebar from "../componets/Sidebar";
import TaskViewSideDrawer from "../componets/comman/TaskViewSideDrawer";
import {
  useRecordingContext
} from "../contexts/useRecordingContext";
import TestCaseViewSideDrawer from "../pages/Private/Projects/TestCases/TestCaseViewDrawer";
import ViewFileSideDrawer from "../pages/Private/Projects/ViewFileSideDrawer";
import ScreenRecorder from "../componets/recording/ScreenRecorder";

export default function PrivateLayout(props) {
  const {
    setOpenRecordScreen,
    setFileTitle,
    loggedUser,
    openRecordScreen,
    fileTitle,
    recordingOrigin,
    isGlobal,
    mediaType
  } = useRecordingContext();
  const isAuthenticated = localStorage.getItem("token");
  const { taskId, fileId, testCaseId, testExecutionId } = useParams();
  const [isSmallSidebar, setIsSmallSidebar] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [showMobileDiv, setShowMobileDiv] = useState(false);
  const location = useLocation();
  const getTitle = () => {
    const pathname = location.pathname;
    switch (true) {
      case pathname === "/user/dashboard":
        return "Dashboard";
      case pathname === "/user/projects":
        return "Projects";
      case pathname === "/user/tasks":
        return "Tasks";
      case pathname === "/user/bugs":
        return "Bugs";
      case pathname === "/user/sprints":
        return "Sprints";
      case pathname === "/user/teams":
        return "Teams";
      case pathname.startsWith("/user/teams/user-profile/"):
        return "Profile";
      case pathname.includes("/testCase/panel/") ||
        pathname.includes(`/testExecution/${testExecutionId}/panel/`) ||
        pathname.includes(`/viewTestExecution/${testExecutionId}/panel/`):
        return "Test Case View";
      case pathname.includes("/panel/"):
        return "Task View";
      case pathname === "/user/setting/manageAccount":
        return "Manage Account";
      case pathname === "/user/setting/billing":
        return "Billing";
      case pathname === "/user/setting/plans":
        return "Plans";
      case pathname === "/user/setting/preferences":
        return "Preferences";
      case pathname === "/user/notifications":
        return "Notifications";
      case pathname.includes("/user/project"):
        return "Project";
      case pathname.includes("/user/recording"):
        return "Recording";
      default:
        return "Default Title";
    }
  };

  useEffect(() => {
    if (
      location.pathname.includes("user/setting") ||
      location.pathname.includes("/user/teams")
    ) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallSidebar(screenWidth <= 1200);

      if (screenWidth <= 903) {
        setShowMobileDiv(true);
      } else {
        setShowMobileDiv(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleClick = () => {
    if (window.innerWidth <= 1274) {
      setIsSmallSidebar(true);
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{` ${getTitle()} | ${process.env.REACT_APP_PROJECT_NAME
            }`}</title>
        </Helmet>
        {isAuthenticated ? (
          <>
            <LoadingBar
              color="#0052cc"
              progress={props.progress}
              onLoaderFinished={() => props.setProgress(0)}
              height={5}
            />
            <Navbar
              setProgress={props.setProgress}
              notificationsCount={props.notificationsCount}
              setNotificationsCount={props.setNotificationsCount}
              latestSprint={props.latestSprint}
              setLatestSprint={props.setLatestSprint}
            />
          </>
        ) : (
          <LandingNavbar />
        )}
        <div className="wrapper d-flex">
          <Sidebar
            isSmallSidebar={isSmallSidebar}
            setIsSmallSidebar={setIsSmallSidebar}
            hideSidebar={hideSidebar}
            setHideSidebar={setHideSidebar}
          />
          <div
            className={`content-wrapper project__overview__responsive ${isSmallSidebar ? "" : "opacity-wrapper"
              }`}
            onClick={handleClick}
          >
            <div className="outlet_main_content">
              <Outlet />
            </div>
            <FooterCopyRight />
          </div>
        </div>
        {taskId && (
          <TaskViewSideDrawer
            taskId={taskId}
            setProgress={props.setProgress}
          />
        )}
        {testCaseId && (
          <TestCaseViewSideDrawer
            testCaseId={testCaseId}
            setProgress={props.setProgress}
            testExecutionId={testExecutionId}
          />
        )}
        {fileId && (
          <ViewFileSideDrawer
            fileId={fileId}
            setProgress={props.setProgress}
          />
        )}
        {showMobileDiv && (
          <div className="mobile-overlay">
            <p>Please use in desktop mode.</p>
          </div>
        )}
        {openRecordScreen && (
          <ScreenRecorder
            setOpenRecordScreen={setOpenRecordScreen}
            mediaType={mediaType}
            setFileTitle={setFileTitle}
            fileTitle={fileTitle}
            isGlobal={isGlobal}
            loggedUserId={loggedUser.id}
            recordingOrigin={recordingOrigin}
          />
        )}
      </div>
    </>
  );
}
