import React, { useEffect, useState } from "react";
import AddReferenceModal from "./AddReferenceModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../../../customAxios";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getItemIconClass } from "../../../../Utils/helpers";
function AddReference({ testCaseDetail, setTestCaseDetail, setProgress }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { testExecutionId, projectId } = useParams()
  const handleUpdateTestCaseReference = (data) => {
    setProgress(30);
    const formData = new FormData();
    const referencesIds = data.map((item) => item.task_id);
    formData.append("reference_ids", JSON.stringify(referencesIds));
    api
      .post(`/test-cases/${testCaseDetail?.id}`, formData)
      .then((res) => {
        setProgress(60);
        toast.success("Test case references updated successfully!");
        setTestCaseDetail((preData) => ({
          ...preData,
          references: res?.data?.references,
        }));
        const data = {
          testCaseId: res?.data?.id,
          references: res?.data?.references,
          action: "ref-update",
          listId: res?.data?.tc_lists_id,
          executionId: parseInt(testExecutionId),
        };
        navigate(location.pathname, { state: { data } });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };

  const getReference = (newData) => {
    handleUpdateTestCaseReference(newData);
  };

  const handleDeselectTestCase = (Id) => {
    const updatedArray = testCaseDetail?.references.filter(
      (ref) => ref.task_id !== Id
    );
    handleUpdateTestCaseReference(updatedArray);
  };

  const handleOpenTaskDrawer = (id, type) => {
    const isPrevExist = localStorage.getItem("prevTestCaseUrl");
    const currentPath = location.pathname;
    if (!isPrevExist) {
      localStorage.setItem("prevTestCaseUrl", currentPath);
    }
    const newUrl = `/user/${type}/panel/${id}/${projectId}`;
    navigate(newUrl);
  };

  return (
    <div className="link-items-box box" id="bx">
      <div className="box-head align-items-center mb_30">
        <h5 className="text-secondary">Add Reference</h5>
        <span
          className="text-primary  add_cursor f-12"
          onClick={() => setOpen(true)}
        >
          + Add Reference
        </span>
      </div>
      {testCaseDetail?.references?.length > 0 ? (
        testCaseDetail?.references?.map((reference, i) => (
          <div key={i} className={`d-flex align-items-center gap-2 mb_12`}>
            <div className={`testCase-small-card w-100 mb-0`}>
              <div className="view-test-case w-100">
                <div className="text d-flex justify-content-between">
                  <div className="d-flex w-95">
                    <div className="icon d-flex">
                      <div className={`${getItemIconClass(reference?.task_type, reference?.task_parent_id)}`}></div>
                    </div>
                    <p className={`text-capitalize cursor_pointer textEllipse case_title w-85 m-0`} onClick={() =>
                      handleOpenTaskDrawer(reference?.task_id, reference.task_type === "B" ? "bugs" : "tasks")
                    }>
                      <span className="text-primary">{reference?.task_id}</span> - {reference.task_name}
                    </p>
                  </div>
                  <span
                    className="float-end add_cursor span-gray-color"
                    onClick={() => handleDeselectTestCase(reference?.task_id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-muted fs-6 mt-2 mb-2">
          There is not references task
        </p>
      )}

      {open && (
        <AddReferenceModal
          openReferenceModal={open}
          setOpenReferenceModal={setOpen}
          isReference={true}
          allReference={getReference}
          references={testCaseDetail?.references}
        />
      )}
    </div>
  );
}

export default AddReference;
