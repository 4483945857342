import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from "react-dom"
const CopyButton = ({ codeBlock }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(codeBlock);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <button onClick={handleCopy} className="copy-button position-absolute d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={copied ? faCheck : faCopy} className={`copy-btn-icon ${copied ? 'checked-icon' : 'copy-icon'} `} /> <span className='text-secondary copy-btn-text'> {copied ? "Copied!" : "Copy"} </span>
        </button>
    );
};

const CustomContentPreview = ({ taskDetail, handleHTMLContentClick, contentType, comment, testCaseDetail }) => {
    let contentToDisplay;
    switch (contentType) {
        case "comment":
            contentToDisplay = comment?.message;
            break;
        case "task":
            contentToDisplay = taskDetail?.task_desc || 'No description';
            break;
        case "expected_result":
            contentToDisplay = testCaseDetail?.expected_result || 'No expected result';
            break;
        case "steps":
            contentToDisplay = testCaseDetail?.steps || "No steps";
            break;
        case "preconditions":
            contentToDisplay = testCaseDetail?.preconditions || 'No preconditions';
            break;
        default:
            contentToDisplay = ""; // Or any fallback value
    }

    const [showMore, setShowMore] = useState(false)
    const descriptionRef = useRef(null);
    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.querySelectorAll('pre').forEach((preBlock) => {

                const fullCodeContent = preBlock.innerText;
                // Ensure no duplicate button
                if (!preBlock.querySelector('.copy-button-wrapper')) {
                    // Create a wrapper div and attach CopyButton to it
                    const buttonWrapper = document.createElement('div');
                    buttonWrapper.className = 'copy-button-wrapper';
                    preBlock.prepend(buttonWrapper);

                    // Render CopyButton component in the wrapper and pass the preBlock element
                    ReactDOM.render(<CopyButton codeBlock={fullCodeContent} />, buttonWrapper);
                }
            });
        }
    }, [contentToDisplay, showMore]);
    return (
        <>
            <span
                ref={descriptionRef}
                className={`font-weight-400 description-preview capitalize-first ${!contentToDisplay ? 'no-description' : ''}`}
                style={{ whiteSpace: 'initial', color: '#000000' }}
                dangerouslySetInnerHTML={{
                    __html: showMore || contentToDisplay?.length <= 1000
                        ? contentToDisplay
                        : contentToDisplay?.substr(0, 1000) + '...',
                }}
                onClick={handleHTMLContentClick}
            />
            {(contentToDisplay
                ? contentToDisplay?.length
                : 0) > 1000 && (
                    <span
                        tabIndex={0}
                        onKeyPress={() => setShowMore(!showMore)}
                        className="link-color cursor-hover text-decoration-none d-block f-14 font-weight-600"
                        onClick={() => setShowMore(!showMore)}
                    >
                        {" "}
                        {showMore ? "Show less" : "Show more"}
                    </span>
                )}
        </>
    );
};

export default CustomContentPreview;
