import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Checkbox, ListItemText, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../../customAxios";

function LinkTestCaseModal(props) {
    const { id, projectId } = useParams();
    const {
        openLinkCaseModal,
        setOpenLinkCaseModal,
        testCases,
        setTestCases,
        setProgress,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTestCases, setSelectedTestCases] = useState([]);
    const [searchItems, setSearchItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const { taskId } = useParams();
    const handleClose = () => {
        setOpenLinkCaseModal(false);
        setSearchItems(null);
        setSelectedTestCases([]);
        setIsLoading(false);
    };

    const handleAddItems = async () => {
        setProgress(30);
        const formData = new FormData();
        formData.append("task_id", taskId);
        formData.append(
            "test_cases",
            JSON.stringify(selectedTestCases?.map((item) => item.id))
        );
        api
            .post(`/linked-test-cases`, formData)
            .then((res) => {
                setProgress(60);
                setTestCases(res?.data?.linkedTestCases?.map((obj) => obj.testcase));
                toast.success("Test case linked successfully!");
            })
            .catch((err) => {
                toast.error("Oops! something went wrong.");
            })
            .finally(() => {
                setProgress(100);
                setOpenLinkCaseModal(false);
            });
    };

    useEffect(() => {
        if (testCases) {
            setSelectedTestCases(testCases || []);
        }
    }, [openLinkCaseModal]);

    const handleDeselectTestCase = async (Id) => {
        const isInitiallyPresent = testCases?.some((item) => item.id === Id);

        if (isInitiallyPresent) {
            try {
                const payload = {
                    task_id: parseInt(taskId),
                    test_case_id: Id,
                };
                await api.post(`/delete-linked-test-cases`, payload); // Adjust API endpoint as needed
                // Remove from state after successful API call
                setTestCases((prev) => prev.filter((item) => item.id !== Id));
            } catch (error) {
                toast.error("Failed to unlink test case.");
            }
        }

        // Always remove from selected test cases
        setSelectedTestCases((prev) => prev.filter((item) => item.id !== Id));
    };

    const fetchItemsFromApi = async (searchQuery) => {
        setIsSearch(true);
        try {
            const response = await api.get(
                `/project/${id || projectId}/search-test-cases?keyword=${searchQuery}`
            );
            setSearchItems(response?.data || []);
        } catch (error) {
            toast.error("Oops! Something went wrong.");
        } finally {
            setIsSearch(false);
        }
    };

    const debouncedSearch = useCallback(
        debounce((query) => fetchItemsFromApi(query), 300),
        []
    );

    useEffect(() => {
        if (searchTerm.trim()?.length > 0) {
            debouncedSearch(searchTerm);
        } else {
            setSearchItems([]); // Clear items if input is empty
        }
        return () => debouncedSearch.cancel(); // Cleanup debounce
    }, [searchTerm, debouncedSearch]);
    const handleCheckboxSelect = (item) => {
        // Add only if not already selected
        setSelectedTestCases((prev) => {
            if (!prev.some((testCase) => testCase.id === item.id)) {
                return [...prev, item];
            }
            return prev; // Do nothing if already selected
        });
    };

    return (
        <Modal
            size="lg"
            show={openLinkCaseModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pt-5 pl-5 add-member_modal"
        >
            <div className="container">
                <div className="main-modal">
                    <Modal.Header className="project-modal_header">
                        <Modal.Title className="d-flex align-items-center">
                            Link Test Cases
                        </Modal.Title>
                        <FontAwesomeIcon icon={faClose} onClick={handleClose} />
                    </Modal.Header>
                    <Modal.Body className="project-modal_body">
                        <div className="fields">
                            <div className="row">
                                <div className="col-md-12 border-black">
                                    <div className="d-flex align-items-center gap-4">
                                        <div className={`field-box w-100`}>
                                            <div className="border-black">
                                                <h5 className="text-dark mt-6px">Select Test Cases</h5>
                                                <Autocomplete
                                                    options={searchItems}
                                                    getOptionLabel={(option) =>
                                                        `${option.tcs_sequence_id} - ${option.name}`
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder={`Type to search`}
                                                            variant="outlined"
                                                            className="mt-5px w-100"
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />
                                                    )}
                                                    inputValue={searchTerm} // Set the input value explicitly
                                                    onInputChange={(event, value) => setSearchTerm(value)} // Update searchTerm based on user input
                                                    renderOption={(props, option) => {
                                                        const isSelected = selectedTestCases?.some(
                                                            (workItem) => workItem.id === option.id
                                                        );
                                                        return (
                                                            <li
                                                                {...props}
                                                                key={option.id}
                                                                style={{
                                                                    backgroundColor: isSelected ? "#F1F1F1" : "",
                                                                }}
                                                                onClick={() => handleCheckboxSelect(option)}
                                                                className={`view-test-case d-flex align-items-center p-2`}
                                                            >
                                                                <ListItemText
                                                                    className="text-capitalize ms-3 textEllipse"
                                                                    primary={
                                                                        <span>
                                                                            <span className="text-primary">
                                                                                {option.tcs_sequence_id}
                                                                            </span>
                                                                            <span className="text-default">
                                                                                {" - "}
                                                                                {option?.name?.length > 50
                                                                                    ? `${option.name.substring(0, 50)}...`
                                                                                    : option.name}
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                />
                                                                {/* <Checkbox
                                                                    checked={isSelected}
                                                                    className="ms-auto"
                                                                /> */}
                                                            </li>
                                                        );
                                                    }}
                                                    noOptionsText="There is no test case, please search and select test cases."
                                                    loading={isSearch}
                                                    loadingText="Searching..."
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-15">
                                    {selectedTestCases?.length > 0 &&
                                        selectedTestCases.map((item, i) => (
                                            <>
                                                <div key={i} className={``}>
                                                    <div
                                                        className={`option-list-custom mb-0 d-flex align-items-center  justify-content-between view-test-case`}
                                                    >
                                                        <div className="d-flex align-items-center w-95">
                                                            <div className="icon me-2">
                                                                <span className={`test-case-icon`}>
                                                                </span>
                                                            </div>
                                                            <p className="mb-0 fw-500 text-capitalize d-flex gap-2 textEllipse w-85 align-items-center ">
                                                                <span className="text-primary">
                                                                    {item?.tcs_sequence_id}
                                                                </span>{" "}
                                                                - {item.name}
                                                            </p>
                                                        </div>
                                                        <span
                                                            className="float-end add_cursor span-gray-color"
                                                            onClick={() => handleDeselectTestCase(item.id)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </span>
                                                    </div>
                                                </div>
                                                {selectedTestCases?.length - 1 > i && <hr className="my-2" />}
                                            </>
                                        ))}
                                </div>
                            </div>
                            <div className="actions">
                                <button className="btn btn-cancel btn-sm" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    className={`btn btn-primary btn-sm ${isLoading ? "pd-cre-task-load" : ""
                                        }`}
                                    disabled={isLoading || selectedTestCases?.length === 0}
                                    onClick={handleAddItems}
                                >
                                    {isLoading ? (
                                        <ClipLoader color="#fff" loading={isLoading} size={20} />
                                    ) : (
                                        "Save"
                                    )}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </div>
        </Modal>
    );
}

export default LinkTestCaseModal;
