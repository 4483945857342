import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarIcon from "@mui/icons-material/DateRange";
import moment from "moment/moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { editTask } from "../../../../Redux/Slice/Task";
import {
  formatName,
  formateDate,
  fullDateFormate,
} from "../../../../Utils/helpers";
import "../../../../assets/scss/Content.scss";
import "../../../../assets/scss/Team.scss";
import "../../../../assets/scss/ViewTask.scss";
import AutocompleteLabels from "../../../../componets/comman/AutoCompleteLables";
import CustomAvatar from "../../../../componets/comman/Task/CustomAvatar";
import TaskAssigned from "../../../../componets/comman/TaskAssigned";
import TaskFollowers from "../../../../componets/comman/TaskFollowers";
import TaskPriority from "../../../../componets/comman/TaskPriority";
import TaskStatus from "../../../../componets/comman/TaskStatus";
import AddTaskModalWindow from "../../../../componets/shared/AddTaskModalWindow";
import { handleUpdateProjectLabels } from "../../../../componets/comman/CommonFunction/TaskFunctions";

function ViewTaskSidebar(props) {
  const {
    taskDetail,
    setTaskDetail,
    projectUser,
    status_list,
    projectLabels,
    projectSprints,
    setTaskProgress,
    setProgress,
    isPublicPage,
    setProjectLabels
  } = props;
  const [editStoryPoint, setEditStoryPoint] = useState(false);
  const [isStoryPoint, setIsStoryPoint] = useState(false);
  const [overDue, setOverDue] = useState(false);
  const dispatch = useDispatch();

  const [taskPriority, setTaskPriority] = useState(
    taskDetail?.task_priority ? taskDetail?.task_priority : ""
  );
  const [prevStoryPoint, setPrevStoryPoint] = useState(
    taskDetail?.task_story_point ? taskDetail?.task_story_point : ""
  );
  const [taskStatus, setTaskStatus] = useState(
    taskDetail?.status_id ? taskDetail?.status_id : ""
  );
  const [parsedDate, setParsedDate] = useState(
    taskDetail?.task_due_date ? new Date(taskDetail?.task_due_date) : null
  );
  const [startDate, setStartDate] = useState(
    taskDetail?.task_start_date ? new Date(taskDetail?.task_start_date) : null
  );
  const [selectedLabels, setSelectedLabels] = useState(
    taskDetail?.labels?.map((label) => label.label_name) || []
  );
  const [open, setOpen] = useState(false);
  const [selectedSprintName, setSelectedSprintName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const handleTaskLabels = async (labels) => {
    setProgress(30);
    const payload = {
      project_id: taskDetail?.project_id,
      labels: JSON.stringify(labels),
    };
    const taskId = taskDetail?.task_id;
    dispatch(editTask({ taskId, payload }))
      .then((response) => {
        setProgress(60);
        if (response?.payload.task) {
          const data = {
            taskId: taskId,
            projectId: taskDetail?.project_id,
            isSprint: taskDetail?.sprintName?.sprint_id,
            updateTask: response?.payload.task,
          };
          navigate(location.pathname, { state: { data } });
          handleUpdateProjectLabels(response?.payload.task, projectLabels, setProjectLabels)
        }
      })
      .catch(() => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };
  const handleStoryPointUpdate = () => {
    const storyPoint = taskDetail?.task_story_point;
    if (!storyPoint || isNaN(storyPoint) || storyPoint < 0 || storyPoint > 10) {
      return;
    }
    if (prevStoryPoint == storyPoint) {
      return;
    } else {
      setProgress(30);
      const payload = {
        project_id: taskDetail?.project_id,
        story_point: storyPoint,
      };
      const taskId = taskDetail?.task_id;
      dispatch(editTask({ taskId, payload }))
        .then((response) => {
          setProgress(60);
          if (response?.payload.task) {
            const data = {
              taskId: taskId,
              projectId: taskDetail?.project_id,
              updateTask: response?.payload.task,
              isSprint: taskDetail?.sprintName?.sprint_id,
            };
            setPrevStoryPoint(storyPoint);
            toast.success(`Task story points changed successfully!`);
            navigate(location.pathname, { state: { data } });
          }
        })
        .catch(() => {
          toast.error("Oops! something went wrong.");
        })
        .finally(() => {
          setProgress(100);
        });
    }
  };

  const refreshPage = (
    taskId,
    _null1,
    _null2,
    _null3,
    _null4,
    taskListId,
    _null5,
    _null6,
    selectedSprint
  ) => {
    setSelectedSprintName(selectedSprint?.name);
  };
  const enterKey = (e) => {
    if (e.key === "Enter") {
      handleStoryPointUpdate();
      setIsStoryPoint(false);
      setEditStoryPoint(false);
    }
  };
  const handleChangeInput = (event) => {
    const { value } = event.target;
    if (value >= 0 && value <= 10) {
      setTaskDetail((prevValues) => ({
        ...prevValues,
        task_story_point: value,
      }));
    }
  };

  const handleDoubleClick = () => {
    setIsStoryPoint(true);
  };
  const handleBlur = () => {
    if (taskDetail?.task_story_point === "") {
      setTaskDetail((prevValues) => ({
        ...prevValues,
        task_story_point: prevStoryPoint,
      }));
    } else {
      handleStoryPointUpdate();
    }
    setIsStoryPoint(false);
    setEditStoryPoint(false);
  };
  const moveToSprint = () => {
    if (isPublicPage) {
      return;
    } else if (taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", {
        position: "bottom-right",
      });
      return;
    } else {
      setOpen(true);
    }
  };
  const handleUpdateTask = (type, data, SelectedSprint) => {
    setProgress(30);
    const payload = {
      project_id: taskDetail?.project_id,
    };
    const taskId = taskDetail?.task_id;

    if (type === "assign") {
      if (data === null) {
        payload.assignees = "[null]";
      } else {
        payload.assignees = JSON.stringify(data);
      }
    } else if (type === "followers") {
      if (data.length === 0) {
        payload.followers = "[null]";
      } else {
        const filteredData = data.filter((item) => item !== null);
        payload.followers = JSON.stringify(filteredData);
      }
    } else if (type === "status") {
      payload.status_id = data;
    } else if (type === "priority") {
      payload.priority = data;
    } else if (type === "date") {
      payload.due_date = moment(data).format("YYYY-MM-DD");
    } else if (type === "start_date") {
      payload.start_date = moment(data).format("YYYY-MM-DD");
    }
    dispatch(editTask({ taskId, payload }))
      .then((response) => {
        setProgress(60);
        if (response?.payload.task) {
          if (type === "start_date") {
            toast.success(`Task start date changed successfully!`);
          } else if (type === "date") {
            toast.success(`Task due date changed successfully!`);
          } else if (type !== "assign" && type !== null) {
            toast.success(`Task ${type} changed successfully!`);
          }
          const data = {
            taskId: taskId,
            projectId: taskDetail?.project_id,
            updateTask: response?.payload.task,
            oldStatusId: taskDetail?.status_id,
            ...(SelectedSprint && { SelectedSprint: SelectedSprint }),
          };
          navigate(location.pathname, { state: { data } });
        }
        setTaskDetail({
          ...taskDetail,
          task_due_date: response?.payload.task.task_due_date,
          task_completed_at: response?.payload.task.task_completed_at,
          status_completed: response?.payload.task.status_completed,
        });
      })
      .catch(() => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };
  const handleSelectedLabelsChange = (newSelectedLabels) => {
    setSelectedLabels(newSelectedLabels);
    handleTaskLabels(newSelectedLabels);
  };
  const isToday = (date) => {
    const today = new Date();
    const d = new Date(date);

    return (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    );
  };

  const CustomInputDue = ({ value, onClick }) => {
    const isDateOverdue =
      parsedDate !== null &&
      (taskDetail?.status_completed === 0
        ? new Date(parsedDate) < new Date()
        : taskDetail?.status_completed === 1 &&
        new Date(parsedDate) < new Date(taskDetail?.task_completed_at) &&
        new Date(parsedDate).getDate() !==
        new Date(taskDetail?.task_completed_at).getDate());
    setOverDue(isDateOverdue);
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: isPublicPage
            ? "default"
            : taskDetail?.userPermission?.can_i_edit === true && "pointer",
          fontSize: "14px",
        }}
        onClick={
          isPublicPage
            ? undefined
            : taskDetail?.userPermission?.can_i_edit
              ? onClick
              : null
        }
      >
        <CalendarIcon style={{ marginRight: "8px" }} />
        {value && !isNaN(new Date(value))
          ? isToday(value)
            ? "Due Today"
            : formateDate(value)
          : "-- -- --"}
      </div>
    );
  };
  const CustomInputStart = ({ value, onClick }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: isPublicPage
            ? "default"
            : taskDetail?.userPermission?.can_i_edit === true && "pointer",
          fontSize: "14px",
        }}
        onClick={
          isPublicPage
            ? undefined
            : taskDetail?.userPermission?.can_i_edit
              ? onClick
              : null
        }
      >
        <CalendarIcon style={{ marginRight: "8px" }} />
        {value && !isNaN(new Date(value)) ? formateDate(value) : "-- -- --"}
      </div>
    );
  };
  return (
    <>
      <div className={"routes"}>
        <div className="zacks d-flex justify-content-between">
          <p className="m-0">Created by:</p>
          <span className="newFeature selected-images-row w_168px textEllipse">
            <div className="d-flex">
              {taskDetail?.created_by_image ? (
                <div
                  className="avatar__border userImages size_cover"
                  style={{
                    backgroundImage: `url('${taskDetail?.created_by_image}')`,
                  }}
                ></div>
              ) : (
                <CustomAvatar
                  name={taskDetail?.created_by_name || ""}
                  backgroundColor={
                    projectUser?.find(
                      (user) => user.name === taskDetail?.created_by_name
                    )?.profile_color
                  }
                />
              )}
              <div className="name-time">
                <span className="user-name ms-2 text-capitalize">
                  {formatName(taskDetail?.created_by_name)}
                </span>
                <span className="time">
                  Created at : {fullDateFormate(taskDetail?.task_created_at)}
                </span>
              </div>
            </div>
          </span>
        </div>

        <div className="zacks d-flex justify-content-between mt-18">
          <p className="m-0">Assigned:</p>
          <TaskAssigned
            allUsers={projectUser}
            assignees={taskDetail?.assignees}
            taskDetail={taskDetail}
            handleUpdateTask={handleUpdateTask}
          />
        </div>
        <div className="zacks d-flex justify-content-between mt-21">
          <p className="m-0">Followers:</p>
          <TaskFollowers
            allUsers={projectUser}
            taskDetail={taskDetail}
            handleUpdateTask={handleUpdateTask}
            isViewTask={true}
            isPublicPage={isPublicPage}
          />
        </div>
        <div className="zacks d-flex justify-content-between mt-21">
          <p className="m-0">Sprint:</p>
          {!taskDetail?.task_parent_id ? (
            taskDetail?.sprintName?.sprint_name ? (
              <p className="m-0 ms-5 text-capitalize textEllipse mx-170">
                {taskDetail?.sprintName?.sprint_name}
              </p>
            ) : selectedSprintName ? (
              <span className="m-0 ms-5 text-capitalize textEllipse mx-170">
                {selectedSprintName}
              </span>
            ) : (
              <span
                className={`text-primary newFeature ${isPublicPage
                  ? "cursor_default"
                  : taskDetail?.userPermission?.can_i_edit === false &&
                  "not-allowed-disable"
                  }`}
                onClick={
                  taskDetail?.userPermission?.can_i_edit ? moveToSprint : null
                }
              >
                Move To Sprint
              </span>
            )
          ) : taskDetail?.parenttaskDetail?.sprintName ? (
            <p className="m-0 text-capitalize">
              {taskDetail?.parentTask?.sprintName?.sprint_name}
            </p>
          ) : (
            <span className=" newFeature">None</span>
          )}
        </div>
        <div className="zacks d-flex justify-content-between mt-21">
          <p className="m-0">Story Points:</p>
          {isStoryPoint ? (
            <input
              type="number"
              name="task_story_point"
              value={taskDetail?.task_story_point}
              onChange={handleChangeInput}
              autoFocus
              onKeyDown={enterKey}
              onBlur={handleBlur}
              className="w-25 hide-spinner"
              style={{ border: "none", outline: "none" }}
            />
          ) : (
            <p
              className={`newFeature m-0 ${isPublicPage
                ? "cursor_default"
                : taskDetail?.userPermission?.can_i_edit === false &&
                "not-allowed-disable"
                }`}
              onMouseOver={() =>
                isPublicPage ? undefined : setEditStoryPoint(true)
              }
              onMouseLeave={() => setEditStoryPoint(false)}
            >
              {editStoryPoint && (
                <FontAwesomeIcon
                  icon={faPencil}
                  color="gray"
                  className="action_cursor_head me-3"
                  onClick={
                    taskDetail?.userPermission?.can_i_edit === false
                      ? null
                      : handleDoubleClick
                  }
                />
              )}
              {taskDetail?.task_story_point ? taskDetail?.task_story_point : 0}
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between mt-21 task-prioritys">
          <p className="zacks m-0">Priority:</p>
          <TaskPriority
            setTaskPriority={setTaskPriority}
            taskPriority={taskPriority}
            handleUpdateTask={handleUpdateTask}
            taskDetail={taskDetail}
            isViewTask={true}
          />
        </div>
        <div className="d-flex justify-content-between mt-6">
          <p className="zacks m-0">Start Date:</p>
          <div className={`task-due-date `}>
            <span
              className={`date ${taskDetail?.userPermission?.can_i_edit === false &&
                "not-allowed-disable"
                } ${isPublicPage ? "cursor_default" : ""}`}
              style={{ padding: "0px" }}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  const newDateFormatted = moment(date).format("YYYY-MM-DD");
                  const oldDateFormatted =
                    moment(startDate).format("YYYY-MM-DD");
                  if (newDateFormatted !== oldDateFormatted) {
                    setStartDate(date);
                    handleUpdateTask("start_date", date);
                  }
                }}
                customInput={<CustomInputStart />}
                popperPlacement="bottom"
                dateFormat="MM/dd/yyyy"
                maxDate={parsedDate}
              />
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-21">
          <p className="zacks m-0">Due Date:</p>
          <div className="task-due-date">
            <span
              className={`date ${taskDetail?.userPermission?.can_i_edit === false &&
                "not-allowed-disable"
                } ${overDue ? "due-date" : ""} ${isPublicPage ? "cursor_default" : ""
                }`}
              style={{ padding: "0px" }}
            >
              <DatePicker
                selected={parsedDate}
                onChange={(date) => {
                  const newDateFormatted = moment(date).format("YYYY-MM-DD");
                  const oldDateFormatted =
                    moment(parsedDate).format("YYYY-MM-DD");
                  if (newDateFormatted !== oldDateFormatted) {
                    setParsedDate(date);
                    handleUpdateTask("date", date);
                  }
                }}
                customInput={<CustomInputDue />}
                popperPlacement="bottom"
                dateFormat="MM/dd/yyyy"
                minDate={startDate}
              />
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-25 task-status">
          <p className="zacks m-0">Status:</p>
          <TaskStatus
            statusList={status_list}
            setTaskStatus={setTaskStatus}
            taskStatus={taskStatus}
            handleUpdateTask={handleUpdateTask}
            taskDetail={taskDetail}
            setTaskProgress={setTaskProgress}
            isViewTask={true}
          />
        </div>
        <div
          className="zacks justify-content-between d-flex align-items-center mt-21"
          style={{ position: "relative" }}
        >
          <>
            <p
              className=""
              style={{ marginRight: "10px", position: "absolute", top: "0" }}
            >
              Label:
            </p>
            <div
              className="label-task labels-view_task"
              style={{ width: "160px", marginLeft: "auto" }}
            >
              <AutocompleteLabels
                selectedLabels={selectedLabels}
                onSelectedLabelsChange={handleSelectedLabelsChange}
                projectLabels={projectLabels?.map((label) => label.name)}
                taskDetail={taskDetail}
                isPublicPage={isPublicPage}
                isEdit={true}
                labelType={
                  taskDetail?.task_type === "T"
                    ? "Task"
                    : taskDetail?.task_type === "E"
                      ? "User Story"
                      : taskDetail?.task_type === "B"
                        ? "Bug"
                        : ""
                }
              />
            </div>
          </>
        </div>
      </div>
      {/* {open && */}
      <AddTaskModalWindow
        open={open}
        setOpen={setOpen}
        taskId={taskDetail?.task_id}
        isTaskList={true}
        refreshPage={refreshPage}
        projectSprints={projectSprints}
        handleUpdateTask={handleUpdateTask}
        isViewTask={true}
      />
      {/* } */}
    </>
  );
}

export default ViewTaskSidebar;
