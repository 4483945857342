import { createContext, useContext, useState } from "react";

const RecorderContext = createContext();

export const RecordingProvider = ({ children }) => {
    const [openRecordScreen, setOpenRecordScreen] = useState(false);
    const [mediaType, setMediaType] = useState("audio");
    const [fileTitle, setFileTitle] = useState("");
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    const [isGlobal, setIsGlobal] = useState(false);
    const [recordingOrigin, setRecordingOrigin] = useState(null); // Initialize with your default state

    return (
        <RecorderContext.Provider
            value={{
                setOpenRecordScreen,
                setMediaType,
                setFileTitle,
                loggedUser,
                setIsGlobal,
                setRecordingOrigin,
                openRecordScreen,
                fileTitle,
                recordingOrigin,
                isGlobal,
                mediaType,
            }}
        >
            {children}
        </RecorderContext.Provider>
    );
};

// Custom hook to use the context
export const useRecordingContext = () => {
    return useContext(RecorderContext);
};
