import React from "react";
import { getStatusColor, getStatusColorStyle } from "../../../../Utils/helpers";
import moment from "moment";
import { Alert } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const Defects = ({ defects, projectStatuses }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleOpenTaskDrawer = (id, projectId) => {
    const isPrevExist = localStorage.getItem("prevTestCaseUrl");
    const currentPath = location.pathname;
    if (!isPrevExist) {
      localStorage.setItem("prevTestCaseUrl", currentPath);
    }
    const newUrl = `/user/bugs/panel/${id}/${projectId}`;
    navigate(newUrl);
  };
  return (
    <>
      {defects && defects?.length === 0 ? (
        <Alert icon={false} severity="success" className="history_description">
          {defects && defects?.length === 0
            ? "There is no bug added for test case"
            : ""}
        </Alert>
      ) : (
        <table className="defect-table">
          <thead>
            <tr className="defect-row">
              <th className="th-results">Result</th>
              <th className="th-date-time">Date & Time</th>
              <th className="th-defect">Defect</th>
              <th className="th-status">Status</th>
            </tr>
          </thead>
          <tbody>
            {defects?.map((defect, index) => (
              <tr key={index} className="defect-row defect-body-row">
                <td className="td-result">
                  <span
                    className={`test-case text-capitalize case-${defect?.result_status}`}
                  >
                    {defect?.result_status}
                  </span>
                </td>
                <td className="td-date-time">
                  {moment(defect?.created_at).format("MMM DD YYYY, h:mm A")}
                </td>
                <td className="td-defect">
                  <p
                    className="text-capitalize"
                    onClick={() =>
                      handleOpenTaskDrawer(defect?.id, defect.project_uid)
                    }
                  >
                    {defect?.name.length > 50
                      ? `${defect?.name?.substring(0, 50)}...`
                      : defect?.name}
                  </p>
                </td>
                <td className="td-status">
                  <span
                    className={`status`}
                    style={getStatusColorStyle(
                      getStatusColor(defect?.status_id, projectStatuses)
                    )}
                  >
                    {defect.task_status_name}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Defects;
