import { faClose, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import WaveSurfer from "wavesurfer.js";
import { api } from "../../customAxios";
import MediaConfirmationDialog from "./MediaConfirmationDialog";

function MediaRecordingModalWindow(props) {
  const {
    openRecordModal,
    setOpenRecordModal,
    mediaType,
    isGlobal,
    setFileTitle,
    fileTitle,
    loggedUserId,
    recordedChunks,
    fileToUpload,
    setOpenRecordScreen,
    setFileToUpload,
    recordingOrigin,
    setAlertText,
    alertText,
    recordedAudio,
    setIsOpenAudioRecording,
  } = props;
  const [apiProgress, setApiProgress] = useState(0);
  const [waitUpload, setWaitUpload] = useState(false);
  const [isCancelRecordingSave, setIsCancelRecordingSave] = useState(false);
  const { taskId, testCaseId } = useParams();

  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);

  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");

  const [isPlaying, setIsPlaying] = useState(false);

  const navigate = useNavigate();
  const handleCloseRecordModal = () => {
    setAlertText({
      heading: "Are you sure you want to discard the recording?",
      subHeading: `Your current recorded ${mediaType === "audio" ? "voice message" : "video"} will be lost!`,
    });
    setIsCancelRecordingSave(true);
  };

  useEffect(() => {
    if (recordedAudio && waveformRef.current) {
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#7fa5e4",
        progressColor: "#0052cc",
        cursorColor: "#0052cc",
        barWidth: 3,
        responsive: true,
        height: 75,
        cursorWidth: 2,
      });

      waveSurferRef.current.load(recordedAudio);

      waveSurferRef.current.on("play", () => setIsPlaying(true));
      waveSurferRef.current.on("pause", () => setIsPlaying(false));
      waveSurferRef.current.on("ready", () => {
        setDuration(formatTime(waveSurferRef.current.getDuration()));
      });

      waveSurferRef.current.on("audioprocess", () => {
        setCurrentTime(formatTime(waveSurferRef.current.getCurrentTime()));
      });

      waveSurferRef.current.on("finish", () => {
        setIsPlaying(false);
        setCurrentTime("00:00");
      });
      return () => {
        waveSurferRef.current.destroy();
      };
    }
  }, []);

  const togglePlayPause = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const confirmTheFileUpload = (formData) => {
    api
      .post("/confirm-the-file-upload", formData)
      .then((resp) => {
        localStorage.setItem(
          "permissions",
          JSON.stringify(resp.data.permissions)
        );
        if (resp?.data?.file) {
          const dateModifiedFile = {
            ...resp.data.file,
            file_created_at: resp.data.file.created_at,
          };
          const fileDataToUpdate = {
            action: "add",
            newFile: isGlobal ? dateModifiedFile : resp.data.file,
          };
          navigate(recordingOrigin, { state: { fileDataToUpdate } });
        }
        toast.success("File uploaded successfully!");
        setWaitUpload(false);
      })
      .catch((error) => {
        console.log(error, "error to confirm file upload");
        setWaitUpload(false);
      });
  };

  const handleSaveAudioVideo = async () => {
    setWaitUpload(true);
    const modelId = isGlobal ? loggedUserId : testCaseId ? testCaseId : taskId ? taskId : ""
    const modelName = isGlobal ? "User" : taskId ? "Task" : testCaseId ? "TestCase" : ""
    let formData = new FormData();
    formData.append("file_name", fileToUpload.name);
    formData.append("file_title", fileTitle);
    formData.append("model_id", modelId);
    formData.append("model_name", modelName);
    formData.append("file_type", fileToUpload?.name?.split(".").pop());
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use((config) => {
      config.onUploadProgress = (progressEvent) => {
        const progressPercentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setApiProgress(progressPercentage);
      };
      return config;
    });

    try {
      const response = await api.post("/upload-file", formData);
      await axiosInstance.put(response.data.upload_url, fileToUpload, {
        headers: {
          "Content-Type": fileToUpload.type,
        },
      });
      localStorage.setItem(
        "permissions",
        JSON.stringify(response?.data?.permissions)
      );
      await confirmTheFileUpload(formData);
      setFileTitle("");
      setApiProgress(0);
    } catch (error) {
      console.error("Error during file upload:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        console.error("Failed to upload file:", error);
      }
    } finally {
      setWaitUpload(false);
      setOpenRecordModal(false);
      if (setOpenRecordScreen) {
        setOpenRecordScreen(false);
      } else {
        setIsOpenAudioRecording(false);
      }
    }
  };

  const handleStopRecordingUpload = () => {
    setFileToUpload(null);
    setOpenRecordModal(false);
    setIsCancelRecordingSave(false);
    if (setOpenRecordScreen) {
      setOpenRecordScreen(false);
    } else {
      setIsOpenAudioRecording(false);
    }
    setFileTitle("");
  };
  return (
    <>
      <Modal
        size="lg"
        show={openRecordModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
        style={{ zIndex: "1500" }}
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title>
                {`Recorded ${mediaType === "audio" ? "voice" : "video"
                  } message!`}
              </Modal.Title>
              <FontAwesomeIcon
                icon={faClose}
                onClick={waitUpload ? undefined : handleCloseRecordModal}
              />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <div className="fields">
                <div className="row">
                  <div className="col-md-6 border-black">
                    <h5>Title</h5>
                    <input
                      type="text"
                      name="name"
                      value={fileTitle}
                      onChange={(e) => setFileTitle(e.target.value)}
                      className={`form-control form-control__input`}
                    />
                  </div>
                  <div className="col-md-12 my-3">
                    {mediaType === "audio" ? (
                      <>
                        <div ref={waveformRef}></div>
                        <div className="d-flex fs-16 fw-bold justify-content-center align-items-center mt-3 gap-3">
                          <span>{currentTime}</span>
                          <Tooltip
                            placement="top"
                            arrow
                            title={`${isPlaying ? "Pause" : "Play"} Recording`}
                          >
                            <button
                              onClick={togglePlayPause}
                              className=" circle-button"
                            >
                              <FontAwesomeIcon
                                icon={isPlaying ? faPause : faPlay}
                              />
                            </button>
                          </Tooltip>
                          <span>{duration}</span>
                        </div>
                      </>
                    ) : (
                      <video
                        controls
                        style={{ width: "100%" }}
                      >
                        {recordedChunks?.map((chunk, index) => (
                          <source
                            key={index}
                            src={URL.createObjectURL(chunk)}
                            type="video/webm"
                          />
                        ))}
                      </video>
                    )}
                  </div>
                </div>
                {apiProgress > 0 && (
                  <Box className="linearProgress">
                    <LinearProgress variant="determinate" value={apiProgress} />
                  </Box>
                )}

                <div className="actions">
                  <button
                    className="btn btn-cancel btn-sm"
                    onClick={waitUpload ? undefined : handleCloseRecordModal}
                    disabled={waitUpload}
                  >
                    Cancel
                  </button>
                  <button
                    className={`btn btn-primary btn-sm ${waitUpload ? "pd-edit-sprint-load" : ""
                      }`}
                    disabled={waitUpload}
                    onClick={handleSaveAudioVideo}
                  >
                    {waitUpload ? (
                      <ClipLoader color="#ffffff" size={20} />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>

      <MediaConfirmationDialog
        isOpenConfirmModal={isCancelRecordingSave}
        alertText={alertText}
        handleCloseConfirmation={() => setIsCancelRecordingSave(false)}
        confirmationType={"cancel upload"}
        handleConfirmation={handleStopRecordingUpload}
      />
    </>
  );
}

export default MediaRecordingModalWindow;
