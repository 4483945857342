import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../../componets/shared/ConfirmDialog";
import { api } from "../../../../customAxios";
import AddCaseModal from "../TestCases/AddCaseModal";
import AddReferenceModal from "../TestCases/AddReferenceModal";
import TestCaseStatus from "../TestCases/TestCaseStatus";
import LinkTestCaseModal from "./LinkTestCaseModal";
import { ClipLoader } from "react-spinners";

function AddTestCase(props) {
  const { taskDetail, setTaskDetail, setProgress, isPublicPage } = props;
  const [openLinkCaseModal, setOpenLinkCaseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddCaseModal, setOpenAddCaseModal] = useState(false);
  const [testCases, setTestCases] = useState([]);
  const [openReferenceModal, setOpenReferenceModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [currentTestCase, setCurrentTestCase] = useState(null);
  const navigate = useNavigate();
  const { taskId, projectId } = useParams();
  useEffect(() => {
    if (taskDetail?.task_id) {
      fetchTestCases();
    }
  }, [taskId]);

  const fetchTestCases = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `/tasks/${taskDetail.task_id}/linked-test-cases`
      );
      const testCasesOnly = response.data.map((obj) => obj.testcase);

      setTestCases(testCasesOnly);
    } catch (error) {
      console.error("Error fetching test cases:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshData = (data, action) => {
    let updatedTestCases = testCases || [];

    if (action === "testCaseAdd") {
      updatedTestCases = [...updatedTestCases, data.newTestCase];
    } else if (action === "removeTestCase") {
      updatedTestCases = updatedTestCases.filter(
        (item) => item.id !== data.testCaseId
      );
    } else if (action === "ref-update") {
      updatedTestCases = updatedTestCases.map((testCase) =>
        testCase.id === data.testCaseId
          ? { ...testCase, references: data.references }
          : testCase
      );
    }
    setTestCases(updatedTestCases);
  };

  const handleTestCaseRefUpdate = async (data) => {
    setProgress(30);
    const formData = new FormData();
    const referencesIds = data.map((item) => item.task_id);
    formData.append("reference_ids", JSON.stringify(referencesIds));
    api
      .post(`/test-cases/${currentTestCase?.id}`, formData)
      .then((res) => {
        setProgress(60);
        toast.success("Test case references updated successfully!");
        const updatedData = {
          references: data,
          testCaseId: currentTestCase?.id,
        };
        refreshData(updatedData, "ref-update");
      })
      .catch((err) => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
        setCurrentTestCase(null);
      });
  };
  const handleOpenConfirmRemoveCase = (testCase) => {
    setCurrentTestCase(testCase);
    setAlert(true);
  };
  const confirmDelete = () => {
    setProgress(30);
    setAlert(false);
    const payload = {
      task_id: parseInt(taskId),
      test_case_id: currentTestCase.id,
    };
    api
      .post(`/delete-linked-test-cases`, payload)
      .then((action) => {
        setProgress(60);
        refreshData({ testCaseId: currentTestCase.id }, "removeTestCase");
        toast.success("Test case removed successfully!");
      })
      .catch((error) => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };
  const handleOpenTestCaseDrawer = (testCaseId) => {
    const isPrevExist = localStorage.getItem("prevTaskUrl");
    const currentPath = location.pathname;
    if (!isPrevExist) {
      localStorage.setItem("prevTaskUrl", currentPath);
    }
    navigate(
      `/user/project/${projectId}/testCase/panel/${testCaseId}/${projectId}/reference/${taskId}`
    );
  };

  return (
    <>
      <div className="box mt-3 mb-4" id="bx">
        <div className="bx-r">
          <div className="box-head zack">
            <h5 className="text-secondary">Test Cases</h5>
            {!isPublicPage && taskDetail?.userPermission?.can_i_edit && (
              <>
                <span
                  className="text-primary fs-12 cursor_pointer me-3 ms-auto"
                  onClick={() => setOpenLinkCaseModal(true)}
                >
                  + Link Test Case
                </span>
                <span
                  className="text-primary fs-12 cursor_pointer"
                  onClick={() => setOpenAddCaseModal(true)}
                >
                  + Add Test Case
                </span>
              </>
            )}
          </div>
          {openLinkCaseModal && (
            <LinkTestCaseModal
              openLinkCaseModal={openLinkCaseModal}
              setOpenLinkCaseModal={setOpenLinkCaseModal}
              setTestCases={setTestCases}
              testCases={testCases}
              setProgress={setProgress}
            />
          )}
          {isLoading ? (
            <div className="w-100 text-center p-3">
              <ClipLoader color="#0052cc" loading={isLoading} size={20} />
            </div>
          ) : (
            <div className="box-body test-cases">
              <div className="row m-0">
                {testCases
                  ? testCases?.map((testCase, i) => (
                      <div className="item" key={i}>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="item-content">
                              <div className="col-md-7">
                                <div
                                  className="text"
                                  onClick={() =>
                                    handleOpenTestCaseDrawer(testCase?.id)
                                  }
                                >
                                  <div className="icon">
                                    <div className="test-case-icon"></div>
                                  </div>
                                  <p className="mb-0 fw-500 cursor_pointer text-capitalize d-flex gap-2 textEllipse w-85 align-items-center ">
                                    <span className="text-primary">
                                      {testCase?.tcs_sequence_id}
                                    </span>{" "}
                                    - {testCase?.name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="actions d-hide">
                                  <div className="actions w-100">
                                    <div
                                      className="add_cursor task_items_date reference w-45"
                                      onClick={() => {
                                        setCurrentTestCase(testCase);
                                        setOpenReferenceModal(true);
                                      }}
                                    >
                                      <div className="task_items_list">
                                        References
                                      </div>
                                    </div>
                                    <TestCaseStatus
                                      status={testCase?.status_name}
                                      testCaseId={testCase?.id}
                                      setProgress={setProgress}
                                      projectId={projectId}
                                      testCaseDetail={testCase}
                                      refreshPage={refreshData}
                                      isSubList={true}
                                    />

                                    <div className="task-action w-88">
                                      <div className="action">
                                        <Dropdown>
                                          <div className={`option-icon`}>
                                            <Dropdown.Toggle
                                              id="dropdown-basic"
                                              className={`button_style button_padding dropdown-toggle btn btn-primary`}
                                            >
                                              <FontAwesomeIcon
                                                icon={faEllipsisV}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleOpenConfirmRemoveCase(
                                                    testCase
                                                  )
                                                }
                                              >
                                                Remove
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </div>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          )}
        </div>
      </div>
      {alert && (
        <ConfirmDialog
          open={alert}
          setOpen={setAlert}
          dialogTitle="Are you sure ?"
          handleConfirmYes={confirmDelete}
        />
      )}

      {openAddCaseModal && (
        <AddCaseModal
          isOpenCaseModelWindow={openAddCaseModal}
          setIsOpenCaseModelWindow={setOpenAddCaseModal}
          refreshPage={refreshData}
          setProgress={setProgress}
          projectId={projectId}
          taskId={taskId}
        />
      )}
      {openReferenceModal && (
        <AddReferenceModal
          openReferenceModal={openReferenceModal}
          setOpenReferenceModal={setOpenReferenceModal}
          isReference={true}
          allReference={handleTestCaseRefUpdate}
          references={currentTestCase?.references}
        />
      )}
    </>
  );
}

export default AddTestCase;
