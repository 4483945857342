import React from 'react'

function StartUpCounter({ countdown, skipCountdown }) {
    return (
        <>
            {countdown > 0 && (
                <div className="countdown-overlay d-flex justify-content-center align-items-center">
                    <div className="start-recording-countBtn fw-bold d-flex flex-column justify-content-center align-items-center rounded-circle">
                        <span key={countdown} className="countdown-number text-white">
                            {countdown}
                        </span>
                        <button
                            className="skip-btn mt-3 fs-16 fw-bold text-white bg-transparent border-0 p-0 add_cursor"
                            onClick={skipCountdown}
                        >
                            Skip
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default StartUpCounter