import React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

function DrawingCanvas({ canvasRef, isDrawing, strokeColor }) {
    if (!isDrawing) return null;

    return (
        <div className={`drawing-canvas  ${isDrawing ? "d-block pointer-auto" : "d-none pointer-none"}`}>
            <ReactSketchCanvas
                ref={canvasRef}
                strokeWidth={4}
                strokeColor={strokeColor}
                canvasColor="transparent"
                style={{ width: "100%", height: "100%", pointerEvents: "auto" }}
            />
        </div>
    );
}

export default DrawingCanvas;
