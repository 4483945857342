import { faClose, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import audioRecordingActive from "../../assets/images/audioRecordActive.gif";
import audioRecordingStatic from "../../assets/images/audioRecordStatic.png";
import MediaControls from "./MediaControls";
import MediaRecordingModalWindow from "./MediaRecordingModalWindow";
import MediaConfirmationDialog from "./MediaConfirmationDialog";
import StartUpCounter from "./StartUpCounter";
import MediaAccessAlertModal from "./MediaAccessAlertModal";
import { toast } from "react-toastify";
function AudioRecorder(props) {
    const {
        mediaType,
        setFileTitle,
        fileTitle,
        isGlobal,
        loggedUserId,
        setIsOpenAudioRecording,
        isOpenAudioRecording,
        recordingOrigin,
    } = props;
    if (!isOpenAudioRecording) {
        return;
    }
    console.log("recorder render", isOpenAudioRecording);

    const mimeType = "audio/webm";
    const [stream, setStream] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0); // 5 minutes in seconds
    const [paused, setPaused] = useState(false);
    const [openRecordModal, setOpenRecordModal] = useState(false);
    const [isOpenRestartConfirmation, setIsOpenRestartConfirmation] =
        useState(false);
    const [isOpenTrashConfirmation, setIsOpenTrashConfirmation] = useState(false);
    const [isOpenControlMenu, setIsOpenControlMenu] = useState(false);
    const [isOpenAccessAlert, setIsOpenAccessAlert] = useState(false);
    const [alertText, setAlertText] = useState({ heading: "", subHeading: "" }); // Store reference to the media recorder
    const timerRef = useRef(null);
    const countdownRef = useRef(null);
    const mediaRecorderRef = useRef(null); // Store MediaStreamAudioSourceNode reference
    const isRestartingRef = useRef(null);
    // Permission and stream setup
    useEffect(() => {
        console.log("init recorder");
        const getMicrophonePermission = async () => {
            if (!("MediaRecorder" in window)) {
                toast.info("The MediaRecorder API is not supported in your browser.");
                setIsOpenAudioRecording(false);
                return;
            }
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                setStream(mediaStream);
                setIsOpenControlMenu(true);
            } catch (err) {
                // Handle different error scenarios
                if (err.name === "NotAllowedError") {
                    setIsOpenAccessAlert(true);
                } else {
                    handleCancelRecording();
                    if (err.name === "NotFoundError") {
                        toast.info(
                            "No microphone was found. Please connect a microphone and try again."
                        );
                    } else if (err.name === "NotReadableError") {
                        toast.info(
                            "Your microphone is currently in use or not accessible. Please close other applications using the microphone and try again."
                        );
                    } else {
                        toast.error(
                            "An error occurred while accessing your microphone: " +
                            err.message
                        );
                    }
                }
            }
        };
        getMicrophonePermission();
        return () => {
            if (
                mediaRecorderRef.current &&
                mediaRecorderRef.current.state !== "inactive"
            ) {
                mediaRecorderRef.current.stop();
            }
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }

            setIsOpenAudioRecording(false);
        };
    }, [isOpenAudioRecording]);

    // Handle the countdown
    useEffect(() => {
        if (countdown === 0 && countdownRef.current) {
            setIsRecording(true);
            if (stream) {
                console.log("startRecording called");
                startRecording();
            }
        }
    }, [countdown, stream]);
    // Start the timer
    useEffect(() => {
        if (!paused && isRecording) {
            console.log("elapsed time", elapsedTime);
            timerRef.current = setInterval(() => {
                setElapsedTime((prev) => prev + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
        }

        return () => clearInterval(timerRef.current);
    }, [isRecording, paused]);

    const startCountdown = () => {
        console.log("countdown start");
        setIsOpenControlMenu(false);
        setCountdown(3); // Start 3-second countdown before recording

        countdownRef.current = setInterval(() => {
            setCountdown((prev) => (prev > 1 ? prev - 1 : 0)); // Countdown logic
        }, 1000);
    };

    const skipCountdown = () => {
        clearInterval(countdownRef.current); // Stop the countdown
        setCountdown(0); // Immediately set countdown to 0
        console.log("countdown skip");
    };
    const startRecording = () => {
        try {
            if (!stream) {
                console.error("No screen stream available.");
                return;
            }

            setIsOpenControlMenu(true);

            const media = new MediaRecorder(stream, { type: mimeType });

            mediaRecorderRef.current = media;
            console.log(media, "from starRecording function ");
            let localAudioChunks = [];

            media.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    localAudioChunks.push(event.data);
                }
            };

            media.onstop = () => {
                console.log("stop called");
                if (isRestartingRef.current) {
                    // Skip processing this recording's data because we are restarting.
                    isRestartingRef.current = false; // reset flag for future recordings
                    return;
                }
                if (localAudioChunks.length === 0) {
                    console.warn("No audio data recorded.");
                    return;
                }

                const audioBlob = new Blob(localAudioChunks, { type: mimeType });
                const audioUrl = URL.createObjectURL(audioBlob);
                const audioFile = new File([audioBlob], uuidv4() + ".mp3", {
                    type: "audio/mp3",
                });
                setAudioFile(audioFile);
                setIsOpenControlMenu(false);
                setIsRecording(false);
                setOpenRecordModal(true);
                setRecordedAudio(audioUrl);
            };

            media.onerror = (error) => {
                console.error("MediaRecorder error:", error);
                toast.error("An error occurred while recording. Please try again.");
                setIsOpenControlMenu(false);
            };

            media.start();
        } catch (error) {
            console.error("Recording failed:", error);
            toast.error(
                "Recording failed. Ensure your microphone is accessible and try again."
            );
        }
    };

    const stopRecording = () => {
        try {
            if (
                mediaRecorderRef.current &&
                mediaRecorderRef.current.state !== "inactive"
            ) {
                mediaRecorderRef.current.stop();
            }

            if (stream) {
                stream.getTracks().forEach((track) => track.stop()); // Stop all tracks
                setStream(null); // Clear stream state
                console.log("stream null");
            }
        } catch (error) {
            console.error("Error stopping recording:", error);
        }
    };

    const togglePause = () => {
        if (mediaRecorderRef.current) {
            if (paused) {
                mediaRecorderRef.current.resume();
            } else {
                mediaRecorderRef.current.pause();
            }
            setPaused(!paused);
        }
    };

    const resetStates = () => {
        setAudioFile(null);
        setRecordedAudio(null);
        setCountdown(0);
        setElapsedTime(0);
        setPaused(false);
        setAlertText({ heading: "", subHeading: "" });
        setIsRecording(false);
    };

    const handleOpenRestartConfirmation = () => {
        if (!paused) {
            togglePause();
        }
        setAlertText({
            heading: "Are you sure you want to restart your recording?",
            subHeading: "Your current recorded voice message will be lost!",
        });
        setIsOpenRestartConfirmation(true);
    };

    const restartRecording = () => {
        if (mediaRecorderRef) {
            isRestartingRef.current = true; // mark that we are restarting
            mediaRecorderRef.current.stop();
        }
        setIsOpenControlMenu(false);
        setPaused(false);
        setRecordedAudio(null);
        setAudioFile(null);
        setIsRecording(false);
        setElapsedTime(0); // Reset timer to 5 minutes
        setIsOpenRestartConfirmation(false);
        startCountdown(); // Start the countdown
    };

    const handleOpenTrashConfirmation = () => {
        if (!paused) {
            togglePause();
        }
        setAlertText({
            heading: "Are you sure you want to cancel your recording?",
            subHeading: "Your current recorded voice message will be lost!",
        });
        setIsOpenTrashConfirmation(true);
    };

    const handleCancelRecording = () => {
        console.log("recording cancel");
        setIsOpenTrashConfirmation(false);
        stopRecording();
        resetStates();
        setIsOpenAudioRecording(false);
        if (isOpenAccessAlert) {
            setIsOpenAccessAlert(false);
        }
    };

    const handleResumeRecording = () => {
        setIsOpenRestartConfirmation(false);
        setIsOpenTrashConfirmation(false);
        togglePause();
    };

    return (
        <div>
            {/* Countdown */}
            <StartUpCounter countdown={countdown} skipCountdown={skipCountdown} />

            <Modal
                size="lg"
                show={isOpenControlMenu}
                centered
                className="pt-5 pl-5 add-member_modal"
            >
                <div className="container">
                    <div className="main-modal">
                        <Modal.Header className="project-modal_header px-0 pb-3">
                            <Modal.Title>Record voice message!</Modal.Title>
                            <FontAwesomeIcon
                                icon={faClose}
                                onClick={() =>
                                    isRecording
                                        ? handleOpenTrashConfirmation() // ✅ Call function properly
                                        : handleCancelRecording()
                                }
                            />
                        </Modal.Header>
                        <Modal.Body className="project-modal_body p-0">
                            {isRecording ? (
                                <>
                                    <img
                                        src={paused ? audioRecordingStatic : audioRecordingActive}
                                        height={100}
                                        alt=""
                                        className="d-block mb-3 mx-auto"
                                    />
                                    <MediaControls
                                        elapsedTime={elapsedTime}
                                        handleOpenRestartConfirmation={
                                            handleOpenRestartConfirmation
                                        }
                                        stopRecording={stopRecording}
                                        paused={paused}
                                        togglePause={togglePause}
                                        handleOpenTrashConfirmation={handleOpenTrashConfirmation}
                                        isAudio={true}
                                    />
                                </>
                            ) : (
                                <button
                                    className="circle-button d-block m-auto"
                                    onClick={startCountdown}
                                    type="button"
                                >
                                    <FontAwesomeIcon icon={faMicrophone} />
                                </button>
                            )}
                        </Modal.Body>
                    </div>
                </div>
            </Modal>

            {/* Recorded Audio Modal */}
            {openRecordModal && (
                <MediaRecordingModalWindow
                    openRecordModal={openRecordModal}
                    setOpenRecordModal={setOpenRecordModal}
                    mediaType={mediaType}
                    setFileTitle={setFileTitle}
                    fileTitle={fileTitle}
                    isGlobal={isGlobal}
                    loggedUserId={loggedUserId}
                    fileToUpload={audioFile}
                    setFileToUpload={setAudioFile}
                    alertText={alertText}
                    setAlertText={setAlertText}
                    recordedAudio={recordedAudio}
                    recordingOrigin={recordingOrigin}
                    setIsOpenAudioRecording={setIsOpenAudioRecording}
                />
            )}
            {/* Confirmation Modals */}
            <MediaConfirmationDialog
                isOpenConfirmModal={
                    isOpenRestartConfirmation || isOpenTrashConfirmation
                }
                alertText={alertText}
                handleResumeRecording={handleResumeRecording}
                confirmationType={isOpenRestartConfirmation ? "restart" : "cancel"}
                handleConfirmation={
                    isOpenRestartConfirmation ? restartRecording : handleCancelRecording
                }
            />
            <MediaAccessAlertModal
                isOpenAccessAlert={isOpenAccessAlert}
                handleClose={handleCancelRecording}
                mediaType={mediaType}
            />
        </div>
    );
}

export default AudioRecorder;
