import { Tooltip } from "@mui/material";
import React from "react";

function CustomTooltip({ title = "", placement = "top", arrow = true, children }) {
    return (
        <Tooltip title={title} placement={placement} arrow={arrow} >
            {children}
        </Tooltip>
    );
}

export default CustomTooltip;
