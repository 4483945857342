import { faClose, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Autocomplete,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import {
    getFileName,
    getStatusColor,
    getStatusName,
    getTimeAgo,
    renderFileIcon,
    shortenFileName,
    testCaseStatuses,
    transformResultsToArray,
} from "../../../../Utils/helpers";
import {
    closeLightBox,
    launchLightBox,
    selectTempFiles,
} from "../../../../componets/comman/CommonFunction/TaskFunctions";
import { api } from "../../../../customAxios";
import AddDefectModal from "./AddDefectModal";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
function AddResultModal(props) {
    const {
        openAddResultModal,
        setOpenAddResultModal,
        setProgress,
        testCaseDetail,
        setTestCaseDetail,
        projectLabels,
        projectUser,
        projectStatuses,
        projectList,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [openDefect, setOpenDefect] = useState(false);
    const [openLightBox, setOpenLightBox] = useState(false);
    const [lightBoxImages, setLightBoxImages] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [isFailedSelected, setIsFailedSelected] = useState(false);
    const { projectId, testCaseId, refTaskId, testExecutionId } = useParams();
    const [fileList, setFileList] = useState([]);
    const storedUserData = localStorage.getItem("user");
    const loggedUser = JSON.parse(storedUserData);
    const [defectList, setDefectList] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [isSearch, setIsSearch] = useState(false);

    const [selectedDefect, setSelectedDefect] = useState(null); //
    const resultFileRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    console.log("taskId", refTaskId)
    const handleStatus = (e) => {
        setSelectedStatus(e.target.value);
        setValue("status", e.target.value, { shouldValidate: true });
    };
    const handleOpenLightBox = (file) => {
        launchLightBox(file, fileList, setOpenLightBox, setLightBoxImages);
    };
    const handleCloseLightBox = () => {
        closeLightBox(setLightBoxImages, setOpenLightBox);
    };

    const handleFileChange = (event) => {
        selectTempFiles(event, setProgress, setFileList);
    };
    const handleDeselect = (file) => {
        const updatedFileList = fileList.filter(
            (selectedFile) => selectedFile !== file
        );
        setFileList(updatedFileList);
    };
    const resultSchema = Yup.object().shape({
        status: Yup.string().required("Status is required"),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(resultSchema),
        mode: "onChange",
        defaultValues: {
            status: "",
        },
    });
    const handleClose = () => {
        setOpenAddResultModal(false);
    };
    const watchStatusField = watch("status");

    useEffect(() => {
        setIsFailedSelected(
            getStatusName(watchStatusField, testCaseStatuses) === "Failed"
                ? true
                : false
        );
    }, [watchStatusField]);

    const fetchProjectBugs = async (searchQuery) => {
        setIsSearch(true);
        try {
            const response = await api
                .get(`/test-case/${testCaseId}/bugs?keyword=${searchQuery}`)
            setDefectList(response?.data || []);
        } catch (error) {
            toast.error("Oops! Something went wrong.");
        } finally {
            setIsSearch(false);
        }
    };

    const debouncedSearch = useCallback(
        debounce((query) => fetchProjectBugs(query), 300),
        []
    );

    useEffect(() => {
        if (searchTerm.trim().length > 0) {
            debouncedSearch(searchTerm);
        }
        return () => debouncedSearch.cancel(); // Cleanup debounce
    }, [searchTerm, debouncedSearch]);
    const onSubmit = async (data) => {
        setIsLoading(true);

        const formData = new FormData();
        if (testExecutionId) {
            formData.append("tc_run_id", testExecutionId);
        }
        formData.append("tc_id", testCaseId);
        formData.append("comment", data.comment);
        formData.append("executed_by", loggedUser.id);
        if (refTaskId) {
            formData.append("task_id", refTaskId);
        }
        formData.append(`status`, getStatusName(data.status, testCaseStatuses).toLowerCase());
        if (isFailedSelected && selectedDefect?.task_id) {
            formData.append("defect_id", selectedDefect?.task_id);
        }
        fileList.forEach((file, index) => {
            formData.append(`file_id[${index}]`, file.id);
        });
        api
            .post(`/test-case-results`, formData)
            .then((resp) => {
                toast.success("Test case result created successfully!");
                setOpenAddResultModal(false);
                const newTestCaseResult = resp?.data;
                setTestCaseDetail((prevDetail) => ({
                    ...prevDetail,
                    testCaseResult: [newTestCaseResult, ...prevDetail.testCaseResult],
                    status_name: newTestCaseResult?.status,
                    ...(newTestCaseResult?.testCasebugs?.id && {
                        testCaseDefects: [newTestCaseResult.testCasebugs, ...prevDetail.testCaseDefects],
                    }),
                }));

                reset();
                const data = {
                    testCaseId: testCaseDetail?.id,
                    projectId: projectId,
                    updatedTestCase: {
                        ...testCaseDetail,
                        status_name: newTestCaseResult?.status,
                    },
                    results: transformResultsToArray(newTestCaseResult?.results?.results || {}),
                    executionId: parseInt(testExecutionId),
                    action: "testCaseUpdate",
                    listId: testCaseDetail?.tc_lists_id,
                };
                navigate(location.pathname, { state: { data } });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Oops! something went wrong.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handleSelectionChange = (event, value) => {
        setSelectedDefect(value); // Store the selected value (the option object)
    };

    return (
        <>
            {openDefect === false && (
                <Modal
                    size="lg"
                    show={openAddResultModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={`pt-5 pl-5 add-member_modal`}
                >
                    <div className="container">
                        <div className="main-modal">
                            <Modal.Header className="project-modal_header">
                                <Modal.Title className="d-flex align-items-center">
                                    Add Result
                                </Modal.Title>
                                <FontAwesomeIcon icon={faClose} onClick={handleClose} />
                            </Modal.Header>
                            <Modal.Body className="project-modal_body">
                                <div className="fields">
                                    <div className="fields">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div
                                                        className={`project-name mt-2 ${errors.status ? " " : "border-black"
                                                            } `}
                                                    >
                                                        <h5 className="text-dark">Status</h5>
                                                        <Select
                                                            className={`mb-2 mt-5px w-100 inputcontrasts inputSelect  ${errors.status
                                                                ? "input-contrasts-invalid mb-2"
                                                                : ""
                                                                }`}
                                                            inputProps={{ "aria-label": "Without label" }}
                                                            {...register("status")}
                                                            value={selectedStatus}
                                                            style={{
                                                                color: getStatusColor(
                                                                    selectedStatus,
                                                                    testCaseStatuses
                                                                ),
                                                            }}
                                                            displayEmpty
                                                            onChange={handleStatus}
                                                        >
                                                            <MenuItem value="" hidden>
                                                                Select status
                                                            </MenuItem>
                                                            {testCaseStatuses &&
                                                                testCaseStatuses?.map((status) => (
                                                                    <MenuItem
                                                                        style={{ color: status?.color }}
                                                                        className="create-task-status"
                                                                        value={status.id}
                                                                    >
                                                                        {status.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        <span className="danger-color error-msg mb-0">
                                                            {errors.status && errors.status.message}
                                                        </span>
                                                    </div>
                                                </div>
                                                {isFailedSelected && (
                                                    <div className="col-md-12">
                                                        <div className="project-name mt-10 border-black">
                                                            <h5 className="text-black">
                                                                Bugs{" "}
                                                                <span
                                                                    className="text-decoration-underline text-primary float-end add_cursor"
                                                                    onClick={() => setOpenDefect(true)}
                                                                >
                                                                    Add
                                                                </span>
                                                            </h5>
                                                            <Autocomplete
                                                                options={defectList}
                                                                getOptionLabel={(option) =>
                                                                    `${option.task_name}`
                                                                }
                                                                value={selectedDefect} // Set the selected value explicitly
                                                                onChange={handleSelectionChange} // Update the selected option
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Search bugs"
                                                                        variant="outlined"
                                                                        className="mt-5px w-100"
                                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                                    />
                                                                )}
                                                                inputValue={searchTerm} // Set the input value explicitly
                                                                onInputChange={(event, value) => setSearchTerm(value)} // Update searchTerm based on user input
                                                                renderOption={(props, option) => (
                                                                    <li
                                                                        {...props}
                                                                        key={option.task_id}
                                                                        className="view-test-case d-flex align-items-center"
                                                                    >
                                                                        <ListItemText
                                                                            className="text-capitalize ms-2"
                                                                            primary={option.task_name}
                                                                        />
                                                                    </li>
                                                                )}
                                                                noOptionsText="There is no bug, please search bug and select for result"
                                                                loading={isSearch} // Show loading indicator
                                                                loadingText="Searching..."
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option.task_id === value?.task_id
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                )}
                                                <div className="col-md-12">
                                                    <h5 className="text-black mt-10">Comment</h5>
                                                    <textarea
                                                        className={`form-control form-control__input execution_text_area mt-5px`}
                                                        placeholder="Write your comment"
                                                        {...register("comment")}
                                                        name="comment"
                                                    ></textarea>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="attachment">
                                                        <label
                                                            htmlFor="resultFileInput"
                                                            className="text-primary cursor_pointer "
                                                        >
                                                            + Add Files
                                                            <input
                                                                id="resultFileInput"
                                                                type="file"
                                                                name="file"
                                                                style={{ display: "none" }}
                                                                onChange={handleFileChange}
                                                                ref={resultFileRef}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="file_row mt-3">
                                                    {fileList.map((file, index) => (
                                                        <div className="file_divs mb-3 project-files-div">
                                                            <div
                                                                className="file_main_section project-files"
                                                                key={index + 1}
                                                            >
                                                                <div
                                                                    className="mic-vid-icon add_cursor"
                                                                    style={{ marginTop: "10px !important" }}
                                                                >
                                                                    <div
                                                                        className="imgs"
                                                                        onClick={() => handleOpenLightBox(file)}
                                                                    >
                                                                        {renderFileIcon(file, true)}
                                                                    </div>
                                                                </div>

                                                                <div className="file_main_section_left">
                                                                    <p className="m-b-10 add_cursor">
                                                                        {shortenFileName(getFileName(file?.name))}
                                                                    </p>
                                                                    <div className="d-flex justify-content-between file-bottom">
                                                                        <div className="d-flex">
                                                                            <p className="time">
                                                                                {" "}
                                                                                {getTimeAgo(file.created_at)}{" "}
                                                                            </p>
                                                                        </div>
                                                                        <div className="dropdown_option">
                                                                            <Dropdown>
                                                                                <div className="option-icon">
                                                                                    <Dropdown.Toggle
                                                                                        id="dropdown-basic"
                                                                                        className="button_style"
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            icon={faEllipsisV}
                                                                                        />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item
                                                                                            onClick={() =>
                                                                                                handleDeselect(file)
                                                                                            }
                                                                                        >
                                                                                            Delete
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </div>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="actions">
                                                <Link
                                                    className="btn btn-cancel btn-sm"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    className={`btn btn-primary btn-sm ${isLoading ? "pd-cre-task-load" : ""
                                                        }`}
                                                    type="submit"
                                                    disabled={isLoading || !watchStatusField}
                                                >
                                                    {isLoading ? (
                                                        <ClipLoader
                                                            color="#ffffff"
                                                            loading={isLoading}
                                                            size={20}
                                                        />
                                                    ) : (
                                                        "Create"
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Modal.Body>
                        </div>
                    </div>
                </Modal>
            )}
            <AddDefectModal
                setProgress={setProgress}
                openDefectModal={openDefect}
                setOpenDefectModal={setOpenDefect}
                projectList={projectList}
                setDefectList={setDefectList}
                projectStatuses={projectStatuses}
                projectUser={projectUser}
                projectLabels={projectLabels}
            />
            <FileViewBox
                images={lightBoxImages}
                isOpen={openLightBox}
                onClose={handleCloseLightBox}
            />
        </>
    );
}

export default AddResultModal;
