import {
  faClose,
  faEllipsisV,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Yup from "yup";
import unassign from "../../../../assets/images/Group 1129@2x.png";
import AutocompleteLabels from "../../../../componets/comman/AutoCompleteLables";
import CustomTextEditor from "../../../../componets/comman/CustomTextEditor";
import { api } from "../../../../customAxios";
import AddReferenceModal from "./AddReferenceModal";
import {
  closeLightBox,
  launchLightBox,
  selectTempFiles,
} from "../../../../componets/comman/CommonFunction/TaskFunctions";
import {
  getFileName,
  getItemIconClass,
  getTimeAgo,
  renderFileIcon,
  shortenFileName,
} from "../../../../Utils/helpers";
import { Dropdown } from "react-bootstrap";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
function AddCaseModal({
  isOpenCaseModelWindow,
  setIsOpenCaseModelWindow,
  refreshPage,
  listId,
  setProgress,
  executionId,
  projectId,
  projectUsers,
  taskId,
}) {
  const fileInputRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [assignedUser, setAssignedUser] = useState(0);
  const [caseDecPre, setCaseDecPre] = useState("");
  const [reference, setReference] = useState([]);
  const [caseDecSteps, setCaseDecSteps] = useState("");
  const [caseDecResult, setCaseDecResult] = useState("");
  const [addReference, setAddReference] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [testCaseLists, setTestCaseLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectAllLabels, setProjectAllLabels] = useState([]);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);

  const fetchLists = async () => {
    try {
      const response = await api.get(`/project/${projectId}/test-case-lists`);
      if (response) {
        setTestCaseLists(response?.data?.tcLists);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  useEffect(() => {
    fetchLists();
  }, []);

  useEffect(() => {
    if (isOpenCaseModelWindow) {
      api.get(`/project/${projectId}/labels`).then((response) => {
        setProjectAllLabels(response.data);
      });
    }
  }, [isOpenCaseModelWindow]);
  const handleFileChange = (event) => {
    selectTempFiles(event, setProgress, setFileList);
  };
  const handleDeselect = (file) => {
    const updatedFileList = fileList.filter(
      (selectedFile) => selectedFile !== file
    );
    setFileList(updatedFileList);
  };
  const getValidationSchema = (executionId) =>
    Yup.object().shape({
      name: Yup.string()
        .required("Title of case is required")
        .max(128, "Exceeded maximum character length of 128"),
      ...(executionId
        ? {
            tc_lists_id: Yup.number().required("Test case list is required"),
          }
        : {}),
    });
  const validationSchema = getValidationSchema(executionId);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      tc_lists_id: "",
    },
  });
  const requiredFields = watch(["tc_lists_id", "name"]);
  const UnassignedUserObject = {
    id: 0,
    name: "Unassigned",
    image: unassign,
    profile_color: "#707070",
  };

  const users = [UnassignedUserObject, ...(projectUsers ?? [])];

  useEffect(() => {
    if (users.length > 0) {
      setAssignedUser(users[0].id);
    }
  }, [users]);

  const handleClose = () => {
    setIsOpenCaseModelWindow(false);
  };
  const handleSelectedLabelsChange = (newSelectedLabels) => {
    setSelectedLabels(newSelectedLabels);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const handleGetEditorStateResult = (currentEditorState) => {
    setCaseDecResult(currentEditorState);
  };
  const handleGetEditorStatePre = (currentEditorState) => {
    setCaseDecPre(currentEditorState);
  };
  const handleGetEditorStateSteps = (currentEditorState) => {
    setCaseDecSteps(currentEditorState);
  };

  const createTestCase = async (data, shouldClose) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("project_id", projectId);
    formData.append("tc_lists_id", listId || data.tc_lists_id);
    if (executionId) {
      formData.append("execution_id", executionId);
    }
    if (taskId) {
      formData.append("temp_ref", 1);
    }
    fileList.forEach((file, index) => {
      formData.append(`file_id[${index}]`, file.id);
    });
    const referencesIds = reference.map((item) => item.task_id);
    formData.append(
      "reference_ids",
      JSON.stringify(taskId ? [taskId] : referencesIds)
    );
    formData.append("preconditions", caseDecPre);
    formData.append("steps", caseDecSteps);
    formData.append("expected_result", caseDecResult);
    formData.append(`labels`, JSON.stringify(selectedLabels));
    api
      .post(`/test-cases`, formData)
      .then((res) => {
        toast.success("Test case created successfully!");
        const dataObj = {
          executionId: executionId,
          newTestCase: res.data,
          listId: listId,
        };
        refreshPage(dataObj, "testCaseAdd");
        if (shouldClose) {
          setIsOpenCaseModelWindow(false);
        }
        reset();
        setCaseDecResult("");
        setCaseDecSteps("");
        setCaseDecPre("");
        setReference([]);
        setSelectedLabels([]);
        setFileList([]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleCreateTestCase = async (data) => {
    await createTestCase(data, true);
  };

  const handleAddNext = async (data) => {
    await createTestCase(data, false);
  };

  const handleGetFile = (file) => {
    setFileList((prevFileList) => [...prevFileList, file]);
  };
  const handleOpenLightBox = (file) => {
    launchLightBox(file, fileList, setOpenLightBox, setLightBoxImages);
  };
  const handleCloseLightBox = () => {
    closeLightBox(setLightBoxImages, setOpenLightBox);
  };
  const getReference = (data) => {
    setReference(data);
  };
  const handleDeleteReference = (Id) => {
    const updatedArray = reference.filter((ref) => ref.task_id !== Id);
    setReference(updatedArray);
  };
  console.log(reference, "refer");
  return (
    <>
      {addReference === false && (
        <Modal
          size="lg"
          show={isOpenCaseModelWindow}
          centered
          className="pt-5 pl-5 add-member_modal case-modal"
        >
          <div className="container">
            <div className="main-modal">
              <Modal.Header className="project-modal_header">
                <Modal.Title className="d-flex align-items-center">
                  Add Test Case
                </Modal.Title>
                <FontAwesomeIcon icon={faClose} onClick={handleClose} />
              </Modal.Header>
              <Modal.Body className="project-modal_body">
                <div className="fields">
                  <form onSubmit={handleSubmit(handleCreateTestCase)}>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className={`project-name ${
                            errors.tc_lists_id ? "" : "border-black"
                          } `}
                        >
                          <h5 className="text-dark">Test Case List</h5>
                          <Select
                            className={`mb-2 mt-1 w-100 inputcontrasts inputSelect ${
                              errors.tc_lists_id
                                ? "input-contrasts-invalid mb-2"
                                : ""
                            } ${!requiredFields[0] ? "placeholder_color" : ""}`}
                            defaultValue=""
                            displayEmpty
                            {...register("tc_lists_id")}
                          >
                            <MenuItem value="" hidden>
                              Select test case list
                            </MenuItem>
                            {testCaseLists &&
                              testCaseLists.map((caseList) => (
                                <MenuItem
                                  className="text-capitalize"
                                  value={caseList?.id}
                                >
                                  {caseList?.name}
                                </MenuItem>
                              ))}
                          </Select>
                          <span className="danger-color error-msg mb-0">
                            {errors.tc_lists_id && errors.tc_lists_id.message}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12 border-black">
                        <h5 className="text-dark mt-6px">Title</h5>
                        <input
                          type="text"
                          name="name"
                          {...register("name")}
                          placeholder="Test case title"
                          className={`form-control form-control__input mt-5px ${
                            errors.name ? "is-invalid_boder" : ""
                          }`}
                          onKeyDown={handleKeyPress}
                        />
                        <span className="danger-color error-msg">
                          {errors.name && errors.name.message}
                        </span>
                      </div>
                      {!taskId && (
                        <div className="col-md-12 ">
                          <div className="reference">
                            <h5 className="text-dark">Reference</h5>
                            <span
                              className="cursor_pointer"
                              onClick={() => setAddReference(true)}
                            >
                              Add
                            </span>
                          </div>
                          <div className="mt-10 mb-20">
                            {reference.map((ref, i) => {
                              return (
                                <div
                                  key={i}
                                  className={`testCase-small-card  d-flex align-items-center justify-content-between view-test-case`}
                                >
                                  <div className="d-flex align-items-center w-95">
                                    <div className="icon me-2">
                                      <span
                                        className={`${getItemIconClass(
                                          ref?.task_type,
                                          ref?.task_parent_id
                                        )}`}
                                      ></span>
                                    </div>
                                    <p className="mb-0 fw-500 text-capitalize d-flex gap-2 textEllipse w-85 align-items-center ">
                                      <span className="text-primary">
                                        {ref?.task_id}
                                      </span>{" "}
                                      - {ref?.task_name}
                                    </p>
                                  </div>
                                  <span
                                    className="float-end add_cursor span-gray-color"
                                    onClick={() =>
                                      handleDeleteReference(ref.task_id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <h5 className="text-dark">Preconditions</h5>
                        <div className="edit__wiki__box_2 mb-3 mt-2 border-black editormodalwindow">
                          <CustomTextEditor
                            handleGetEditorState={handleGetEditorStatePre}
                            placeholder={"Write your preconditions"}
                            value={caseDecPre}
                            handleGetFile={handleGetFile}
                            isUploadTempFile={true}
                            setProgress={setProgress}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <h5 className="text-dark">Steps</h5>
                        <div className="edit__wiki__box_2 mb-3 mt-2 border-black editormodalwindow">
                          <CustomTextEditor
                            handleGetEditorState={handleGetEditorStateSteps}
                            placeholder={"Write your steps"}
                            value={caseDecSteps}
                            handleGetFile={handleGetFile}
                            isUploadTempFile={true}
                            setProgress={setProgress}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <h5 className="text-dark">Expected Result</h5>
                        <div className="edit__wiki__box_2 mb-3 mt-2 border-black editormodalwindow">
                          <CustomTextEditor
                            handleGetEditorState={handleGetEditorStateResult}
                            placeholder={"Write your expected result"}
                            value={caseDecResult}
                            handleGetFile={handleGetFile}
                            isUploadTempFile={true}
                            setProgress={setProgress}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                    <div className="project-name task-follower mt-1 border-black">
                      <h5 className="text-dark">Assigned To</h5>
                      <div className="mb-2 mt-1 w-100">
                        <CustomUserAutoCompleteController
                          fieldName={"assignees"}
                          allUsers={users}
                          control={control}
                          placeholderText={"Select Assignee"}
                          isSingle={true}
                        />
                      </div>
                    </div>
                  </div> */}
                      {/* <div className="col-md-6">
                    <div className="project-name task-follower border-black">
                      <h5 className="text-dark mb_5">Followers</h5>
                      <Select
                                                className="mb-2 mt-1 w-100 inputcontrasts"
                                                multiple
                                                displayEmpty
                                                value={selectedFollowers}
                                                onChange={(event) => setSelectedFollowers(event.target.value)}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return 'Select Followers';
                                                    }

                                                    return selected.map((value) => {
                                                        const user = users.find(user => user.id === value);
                                                        return (
                                                            <Avatar
                                                                key={user.id}
                                                                src={user.image}
                                                                alt={user.name}
                                                                sx={{ width: 24, height: 24, marginRight: 1 }}
                                                            />
                                                        );
                                                    });
                                                }}
                                            >
                                                {users.map((user) => (
                                                    <MenuItem key={user.id} value={user.id}>
                                                        <Avatar
                                                            src={user.image}
                                                            alt={user.name}
                                                            sx={{ width: 24, height: 24, marginRight: 1 }}
                                                        />
                                                        {user.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                      <CustomUserAutoCompleteController
                        fieldName={"followers"}
                        allUsers={users}
                        control={control}
                        placeholderText={"Select Followers"}
                      />
                    </div>
                  </div> */}
                      <div className="col-md-12">
                        <div className="attachment">
                          <label
                            htmlFor="fileInput"
                            className="text-primary cursor_pointer "
                          >
                            + Add Files
                            <input
                              id="fileInput"
                              type="file"
                              name="file"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="file_row mt-3">
                        {fileList.map((file, index) => (
                          <div className="file_divs mb-3 project-files-div">
                            <div
                              className="file_main_section project-files"
                              key={index + 1}
                            >
                              <div
                                className="mic-vid-icon add_cursor"
                                style={{ marginTop: "10px !important" }}
                              >
                                <div
                                  className="imgs"
                                  onClick={() => handleOpenLightBox(file)}
                                >
                                  {renderFileIcon(file, true)}
                                </div>
                              </div>

                              <div className="file_main_section_left">
                                <p className="m-b-10 add_cursor">
                                  {shortenFileName(getFileName(file?.name))}
                                </p>
                                <div className="d-flex justify-content-between file-bottom">
                                  <div className="d-flex">
                                    <p className="time">
                                      {" "}
                                      {getTimeAgo(file.created_at)}{" "}
                                    </p>
                                  </div>
                                  <div className="dropdown_option">
                                    <Dropdown>
                                      <div className="option-icon">
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          className="button_style"
                                        >
                                          <FontAwesomeIcon icon={faEllipsisV} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => handleDeselect(file)}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </div>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-md-6 border-black label_create_task task-labels modal-label">
                        <h5 className="text-dark">Labels</h5>
                        <AutocompleteLabels
                          borderNone={true}
                          className="inputcontrasts"
                          selectedLabels={selectedLabels}
                          projectLabels={projectAllLabels?.map(
                            (label) => label.name
                          )}
                          onSelectedLabelsChange={handleSelectedLabelsChange}
                        />
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        className="btn btn-cancel btn-sm"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <div className="next-cret-test">
                        <button
                          type="button"
                          className="btn add-next"
                          onClick={handleSubmit(handleAddNext)}
                          disabled={!isValid || isLoading}
                        >
                          Add & Next
                        </button>
                        <button
                          className={`btn btn-primary btn-sm ${
                            isLoading ? "pd-cre-test-load" : ""
                          }`}
                          type="submit"
                          disabled={!isValid || isLoading}
                        >
                          {isLoading ? (
                            <ClipLoader
                              color="#fff"
                              loading={isLoading}
                              size={20}
                            />
                          ) : (
                            "Create Test Case"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </div>
          </div>
          <FileViewBox
            images={lightBoxImages}
            isOpen={openLightBox}
            onClose={handleCloseLightBox}
          />
        </Modal>
      )}

      {addReference && (
        <AddReferenceModal
          isReference={true}
          openReferenceModal={addReference}
          setOpenReferenceModal={setAddReference}
          allReference={getReference}
          references={reference}
        />
      )}
    </>
  );
}

export default AddCaseModal;
