import { faClose, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DrawingCanvas from "./DrawingCanvas";
import MediaConfirmationDialog from "./MediaConfirmationDialog";
import MediaControls from "./MediaControls";
import MediaRecordingModalWindow from "./MediaRecordingModalWindow";
import StartUpCounter from "./StartUpCounter";
import { toast } from "react-toastify";
import MediaAccessAlertModal from "./MediaAccessAlertModal";
import ClipLoader from "react-spinners/ClipLoader";

function ScreenRecorder(props) {
    const {
        mediaType,
        setFileTitle,
        fileTitle,
        isGlobal,
        loggedUserId,
        setOpenRecordScreen,
        recordingOrigin,
    } = props;
    const [screenStream, setScreenStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [videoFileData, setVideoFileData] = useState(null);
    const [openRecordModal, setOpenRecordModal] = useState(false);
    const [showStartRecordingBtn, setShowStartRecordingBtn] = useState(true);
    const [isCapturing, setIsCapturing] = useState(false);
    const [isOpenRestartConfirmation, setIsOpenRestartConfirmation] =
        useState(false);
    const [isOpenTrashConfirmation, setIsOpenTrashConfirmation] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(300); // 5 minutes in seconds
    const [paused, setPaused] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isDrawing, setIsDrawing] = useState(false);
    const canvasRef = useRef(null);
    const [strokeColor, setStrokeColor] = useState("#ff0000");
    const [showFloatingMenu, setShowFloatingMenu] = useState(false);
    const [isOpenAccessAlert, setIsOpenAccessAlert] = useState(false);
    const [notificationPermission, setNotificationPermission] =
        useState("default");
    const [alertText, setAlertText] = useState({
        heading: "",
        subHeading: "",
    });
    const timerRef = useRef(null);
    const countdownRef = useRef(null);
    const navigate = useNavigate();
    const notificationRef = useRef(null);
    const stopHandlerRef = useRef(null); // Store the stop handler
    const isRestartingRef = useRef(null);
    const checkNotificationPermission = async () => {
        const permission = await Notification.requestPermission();
        setNotificationPermission(permission);
    };
    useEffect(() => {
        checkNotificationPermission();
    }, []);

    // Start the countdown
    const startCountdown = (initialStream) => {
        const recordingStream = initialStream || screenStream;
        setCountdown(3);

        // Start the countdown
        countdownRef.current = setInterval(() => {
            setCountdown((prev) => (prev > 1 ? prev - 1 : 0)); // Countdown logic
        }, 1000);

        // This block runs once the countdown starts
        console.log("recordingStream stream from counter", recordingStream);

        if (recordingStream) {
            const videoTrack = recordingStream.getVideoTracks()[0];

            // Define the event handler to stop the recording
            stopHandlerRef.current = () => {
                // Prevent stopping after countdown finishes
                console.log("Stop during countdown detected");
                handleCancelRecording(); // Call the function to stop the recording
                recordingStream.getAudioTracks()[0]?.stop();
            };

            // Attach the event listener only during the countdown
            videoTrack.addEventListener("ended", stopHandlerRef.current);

            // Remove the event listener after the countdown ends (after 3 seconds)
            setTimeout(() => {
                if (countdown === 0 && stopHandlerRef.current) {
                    videoTrack.removeEventListener("ended", stopHandlerRef.current);
                    stopHandlerRef.current = null; // Clear the reference
                    console.log("Event listener removed after countdown");
                }
            }, 3000); // After 3 seconds, remove the listener
        }
    };

    // Function to skip countdown
    const skipCountdown = () => {
        clearInterval(countdownRef.current); // Stop the countdown
        setCountdown(0); // Immediately set countdown to 0
    };

    // Start recording after countdown
    useEffect(() => {
        if (countdown === 0 && countdownRef.current) {
            console.log("countdown state", countdown);
            clearInterval(countdownRef.current);
            setShowFloatingMenu(true);
            if (screenStream) {
                startRecording();
            }
        }
    }, [countdown, screenStream]);

    // Start the timer
    useEffect(() => {
        if (showFloatingMenu && !paused) {
            timerRef.current = setInterval(() => {
                setElapsedTime((prev) => (prev > 0 ? prev - 1 : 0));
            }, 1000);
        } else {
            clearInterval(timerRef.current);
        }

        return () => clearInterval(timerRef.current);
    }, [showFloatingMenu, paused]);

    // Start screen capture
    const startCapture = async () => {
        try {
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
            });

            const combinedStream = new MediaStream([
                ...screenStream.getTracks(),
                ...audioStream.getTracks(),
            ]);
            setShowStartRecordingBtn(false);
            setScreenStream(combinedStream);
            setRecordedChunks([]);
            setElapsedTime(300);
            startCountdown(combinedStream);
        } catch (error) {
            if (error.name === "NotAllowedError") {
                // User canceled the screen sharing prompt or permission is permanently denied.
                setIsOpenAccessAlert(true);
            } else {
                handleCancelRecording();
                if (error.name === "NotFoundError") {
                    toast.info(
                        "No screen capture device found. Please check your system settings."
                    );
                } else if (error.name === "NotReadableError") {
                    toast.info(
                        "Screen capture device is busy or not accessible. Please close any conflicting applications and try again."
                    );
                } else {
                    toast.error("Error capturing screen:", error);
                }
            }
        } finally {
            setIsCapturing(false);
            setShowStartRecordingBtn(false);
        }
    };

    // Start recording
    const startRecording = () => {
        console.log("start recording", screenStream);

        if (!screenStream) {
            console.error("No screen stream available.");
            return;
        }

        const recorder = new MediaRecorder(screenStream);
        const chunks = [];
        screenStream.getVideoTracks()[0].addEventListener("ended", () => {
            recorder.stop();
            screenStream.getAudioTracks()[0].stop();
        });
        recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
                chunks.push(e.data);
            }
        };
        recorder.onstop = () => {
            if (isRestartingRef.current) {
                // Skip processing this recording's data because we are restarting.
                isRestartingRef.current = false; // reset flag for future recordings
                return;
            }

            const audioBlob = new Blob(chunks, { type: "video/webm" });
            const videoFile = new File(
                [audioBlob],
                uuidv4("video_file_" + Math.floor(Math.random() * 10000)) + ".mp4",
                { type: "video/mp4" }
            );
            setVideoFileData(videoFile);
            setRecordedChunks(chunks);
            setIsOpenRestartConfirmation(false);
            setIsOpenTrashConfirmation(false);
            setShowFloatingMenu(false);
            setIsDrawing(false);
            setOpenRecordModal(true);
            navigate(recordingOrigin);
        };

        recorder.start();
        setMediaRecorder(recorder);
    };

    // 🛠️ Process thumbnails & VTT file without blocking Plyr
    // const processThumbnails = async (videoFile) => {
    //     try {
    //         const thumbnails = await generateThumbnails(videoFile);
    //         const { vttFile, firstThumbnail } = createVTTFile(thumbnails);
    //         setVttFile(URL.createObjectURL(vttFile)); // For Plyr
    //         setPosterImage(firstThumbnail); // Use first thumbnail as poster

    //         console.log(vttFile, firstThumbnail, "created poster and vtt file")
    //     } catch (error) {
    //         console.warn("❌ Thumbnail/VTT generation failed:", error);
    //         setVttFile(null); // Ensure Plyr still works without VTT
    //         setPosterImage(""); // No poster if thumbnails fail
    //     }
    // };

    // auto stop after 5 min recording
    useEffect(() => {
        if (
            mediaRecorder &&
            mediaRecorder.state !== "inactive" &&
            elapsedTime === 0
        ) {
            stopRecording(); // Stop recording
            // Send a notification when the time is up
            if (notificationPermission === "granted") {
                notificationRef.current = new Notification("Recording stopped", {
                    body: "Recording stopped due to time limit reached.",
                });
            }
        }
    }, [elapsedTime]);

    // Stop recording
    const stopRecording = () => {
        try {
            if (mediaRecorder && mediaRecorder.state !== "inactive") {
                mediaRecorder.stop();
                if (screenStream) {
                    screenStream.getTracks().forEach((track) => track.stop());
                    setScreenStream(null);
                }
            }
        } catch (error) {
            console.error("Error stopping recording:", error);
        }
    };
    // Pause/resume recording
    const togglePause = () => {
        if (mediaRecorder) {
            if (paused) {
                mediaRecorder.resume();
            } else {
                mediaRecorder.pause();
            }
            setPaused(!paused);
        }
    };

    // Reset all states to initial values
    const resetStates = () => {
        setScreenStream(null);
        setMediaRecorder(null);
        setRecordedChunks([]);
        setVideoFileData(null);
        setOpenRecordModal(false);
        setShowStartRecordingBtn(true);
        setIsOpenRestartConfirmation(false);
        setIsOpenTrashConfirmation(false);
        setElapsedTime(300);
        setPaused(false);
        setCountdown(0);
        setShowFloatingMenu(false);
        setAlertText({
            heading: "",
            subHeading: "",
        });
        setIsDrawing(false);
        setStrokeColor("#ff0000");
    };

    const handleCloseScreenOverlay = () => {
        resetStates();
        setOpenRecordScreen(false);
    };

    const handleOpenRestartConfirmation = () => {
        if (!paused) {
            togglePause();
        }
        setAlertText({
            heading: "Are you sure you want to restart your recording?",
            subHeading: "Your current recorded video will be lost!",
        });
        setIsOpenRestartConfirmation(true);
    };

    const restartRecording = () => {
        if (mediaRecorder) {
            isRestartingRef.current = true; // mark that we are restarting
            mediaRecorder.stop(); // explicitly stop the current recorder
        }
        setShowFloatingMenu(false);
        setPaused(false);
        setRecordedChunks([]);
        setVideoFileData(null);
        setElapsedTime(300); // Reset timer to 5 minutes
        setIsOpenRestartConfirmation(false);
        startCountdown(); // Start the countdown
    };

    const handleOpenTrashConfirmation = () => {
        if (!paused) {
            togglePause();
        }
        setAlertText({
            heading: "Are you sure you want to cancel your recording?",
            subHeading: "Your current recorded video will be lost!",
        });
        setIsOpenTrashConfirmation(true);
    };

    const handleCancelRecording = () => {
        console.log("cancel recording");
        setIsOpenTrashConfirmation(false);
        stopRecording();
        resetStates();
        setOpenRecordScreen(false);
        if (isOpenAccessAlert) {
            setIsOpenAccessAlert(false);
        }
    };

    const handleResumeRecording = () => {
        setIsOpenRestartConfirmation(false);
        setIsOpenTrashConfirmation(false);
        togglePause();
    };

    return (
        <div className="screen-recording-overlay">
            {/* Countdown */}
            <StartUpCounter countdown={countdown} skipCountdown={skipCountdown} />

            {/* Floating Control Menu */}
            {showFloatingMenu && (
                <MediaControls
                    elapsedTime={elapsedTime}
                    handleOpenRestartConfirmation={handleOpenRestartConfirmation}
                    stopRecording={stopRecording}
                    paused={paused}
                    togglePause={togglePause}
                    handleOpenTrashConfirmation={handleOpenTrashConfirmation}
                    setStrokeColor={setStrokeColor}
                    isDrawing={isDrawing}
                    setIsDrawing={setIsDrawing}
                    canvasRef={canvasRef}
                />
            )}

            {/* Start Recording Button */}
            <Modal
                size="md"
                show={showStartRecordingBtn}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pt-5 pl-5 add-member_modal"
                style={{ zIndex: "1500" }}
            >
                <div className="container">
                    <div className="main-modal">
                        <Modal.Header className="project-modal_header px-0 pb-3">
                            <Modal.Title>Record video message!</Modal.Title>
                            <FontAwesomeIcon
                                icon={faClose}
                                onClick={handleCloseScreenOverlay}
                            />
                        </Modal.Header>
                        <Modal.Body className="project-modal_body p-0">
                            <div className="fields">
                                {!screenStream && !recordedChunks.length && (
                                    <>
                                        <button
                                            onClick={() => {
                                                if (!isCapturing) {
                                                    setIsCapturing(true);
                                                    startCapture();
                                                }
                                            }}
                                            disabled={isCapturing}
                                            className="btn btn-primary btn-sm d-flex align-items-center gap-2 m-auto"
                                        >
                                            {isCapturing ? (
                                                <ClipLoader
                                                    loading={isCapturing}
                                                    color="#fff"
                                                    size={20}
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={faVideo} />
                                            )}
                                            Start Recording
                                        </button>

                                        <p className="text-muted  mb-0 mt-3">
                                            Recording will automatically stop after 5 minutes.
                                        </p>
                                    </>
                                )}
                            </div>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>

            {/* drawing canvas */}

            {/* Drawing Canvas (Full Screen) */}
            {isDrawing && (
                <DrawingCanvas
                    isDrawing={isDrawing}
                    strokeColor={strokeColor}
                    canvasRef={canvasRef}
                />
            )}

            {/* Show Recorded Video in Modal */}
            {openRecordModal && (
                <MediaRecordingModalWindow
                    openRecordModal={openRecordModal}
                    setOpenRecordModal={setOpenRecordModal}
                    mediaType={mediaType}
                    setFileTitle={setFileTitle}
                    fileTitle={fileTitle}
                    isGlobal={isGlobal}
                    loggedUserId={loggedUserId}
                    recordedChunks={recordedChunks}
                    fileToUpload={videoFileData}
                    setOpenRecordScreen={setOpenRecordScreen}
                    setFileToUpload={setVideoFileData}
                    recordingOrigin={recordingOrigin}
                    setAlertText={setAlertText}
                    alertText={alertText}
                    canvasRef={canvasRef}
                />
            )}

            {/* Confirmation Modal */}
            <MediaConfirmationDialog
                isOpenConfirmModal={
                    isOpenRestartConfirmation || isOpenTrashConfirmation
                }
                alertText={alertText}
                handleResumeRecording={handleResumeRecording}
                confirmationType={isOpenRestartConfirmation ? "restart" : "cancel"}
                handleConfirmation={
                    isOpenRestartConfirmation
                        ? restartRecording
                        : isOpenTrashConfirmation
                            ? handleCancelRecording
                            : undefined
                }
            />
            <MediaAccessAlertModal
                isOpenAccessAlert={isOpenAccessAlert}
                handleClose={handleCancelRecording}
                mediaType={mediaType}
            />
        </div>
    );
}

export default ScreenRecorder;
