import { Alert } from "@mui/material";
import React, { useState } from "react";
import {
  fullDateFormate,
  getCreatedTime,
  getFileName,
  getTimeAgo,
  getUserName,
  renderFileIcon,
  shortenFileName,
} from "../../../../Utils/helpers";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
import {
  closeLightBox,
  launchLightBox,
} from "../../../../componets/comman/CommonFunction/TaskFunctions";
const ResultComment = ({ results, projectUsers }) => {
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxImages, setLightBoxImages] = useState([]);

  const handleOpenLightBox = (file, resultFiles) => {
    launchLightBox(file, resultFiles, setOpenLightBox, setLightBoxImages);
  };

  const handleCloseLightBox = () => {
    closeLightBox(setLightBoxImages, setOpenLightBox);
  };
  return (
    <>
      {results && results?.length === 0 ? (
        <Alert icon={false} severity="success" className="history_description">
          {results && results?.length === 0
            ? "There is no result added for test case"
            : ""}
        </Alert>
      ) : (
        <table className="result-table">
          <thead>
            <tr>
              <th className="th-results">Results</th>
              <th className="th-comments">Comments</th>
            </tr>
          </thead>
          <tbody>
            {results?.map((result, index) => (
              <tr key={index}>
                <td className="td-result">
                  <span
                    className={`status case-${result?.status?.toLowerCase()} capitalize-first`}
                  >
                    {result.status}
                  </span>
                  <div className="result-date-time">
                    {fullDateFormate(result?.created_at)} /{" "}
                    {getCreatedTime(result?.created_at)}
                  </div>
                  <div className="result-createdBy">
                    By{" "}
                    <span>
                      {getUserName(projectUsers, result?.executed_by)}
                    </span>
                  </div>
                </td>
                <td className="td-comments capitalize-first">
                  <p className="mt-3">{result?.comment}</p>
                  <div className="file_row">
                    {result?.files?.map((file, index) => (
                      <div className="file_divs mb-3 project-files-div">
                        <div
                          className="file_main_section project-files"
                          key={index + 1}
                        >
                          <div
                            className="mic-vid-icon add_cursor"
                            style={{ marginTop: "10px !important" }}
                          >
                            <div
                              className="imgs"
                              onClick={() =>
                                handleOpenLightBox(file, result?.files)
                              }
                            >
                              {renderFileIcon(file, true)}
                            </div>
                          </div>

                          <div className="file_main_section_left">
                            <p className="m-b-10 add_cursor">
                              {shortenFileName(getFileName(file?.name))}
                            </p>
                            <div className="d-flex justify-content-between file-bottom">
                              <div className="d-flex">
                                <p className="time">
                                  {" "}
                                  {getTimeAgo(file.created_at)}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <FileViewBox
        images={lightBoxImages}
        isOpen={openLightBox}
        onClose={handleCloseLightBox}
      />
    </>
  );
};

export default ResultComment;
