import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Camera from "../../../../assets/images/camera.png";
import Microphone from "../../../../assets/images/microphone.png";
import { refreshFiles } from "../../../../componets/comman/CommonFunction/MediaFileCommonFunction";
import AudioRecorder from "../../../../componets/recording/AudioRecorder";
import MediaFileCard from "../../../../componets/shared/MediaFileCard";

function TaskMediaRecorder(props) {
  const { taskDetail, setProgress, isPublicPage } = props;
  const { testCaseId } = useParams()
  const [mediaFiles, setMediaFiles] = useState(taskDetail?.media_files || []);
  const location = useLocation()
  const [isOpenAudioRecording, setIsOpenAudioRecording] = useState(false);
  const navigate = useNavigate()
  if (testCaseId && !taskDetail.userPermission) {
    return null
  }
  // ✅ Only call useRecordingContext when not in public mode
  const recordingContext = !isPublicPage
    ? require("../../../../contexts/useRecordingContext").useRecordingContext()
    : null;

  const {
    setOpenRecordScreen,
    setMediaType,
    setFileTitle,
    setIsGlobal,
    setRecordingOrigin,
    openRecordScreen,
    recordingOrigin,
    mediaType,
    fileTitle,
  } = recordingContext || {};


  useEffect(() => {
    const updatedFileData = location?.state?.fileDataToUpdate;

    if (updatedFileData) {
      const { action, newFile } = updatedFileData;
      refreshMediaFiles(action, newFile);

      // **Clear the location state after processing it**
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleOpenAudioVideo = (type) => {
    if (openRecordScreen) {
      return toast.info("Screen recording is already in progress.");
    }

    const jsonString = localStorage.getItem('permissions');
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject?.videos?.limit_value != jsonObject?.videos?.limit_count) {
      setFileTitle(
        `${taskDetail?.task_name || taskDetail?.name} - ${moment().format("MMMM Do YYYY")}`
      );
      setMediaType(type);
      if (type === "audio") {
        setIsOpenAudioRecording(true);
      } else {
        setOpenRecordScreen(true);
      }
      setIsGlobal(false);
      setRecordingOrigin(location.pathname);
    } else {
      toast.info(`Your ${type}s limit has been exceeded.`)
    }
  };
  const refreshMediaFiles = (action, fileData) => {
    refreshFiles(action, fileData, "general", setMediaFiles, mediaFiles);
  };
  return (
    <>
      <div className="box" id="bx">
        <div className="box-head">
          <div className="text">
            <h5 className="mt-3 text-secondary ml-0">Record Message.</h5>
          </div>
        </div>
        {!isPublicPage && <div className="box-body d-flex">
          <button
            className={`btn btn-cancel recording_btn ${taskDetail?.userPermission?.can_i_edit
              ? "cursor_pointer"
              : "not-allowed-disable sprint-action"
              }`}
            onClick={
              taskDetail?.userPermission?.can_i_edit
                ? () => handleOpenAudioVideo("audio")
                : null
            }
            style={{
              color: taskDetail?.userPermission?.can_i_edit ? "initial" : "gray",
              cursor: taskDetail?.userPermission?.can_i_edit
                ? "pointer"
                : "not-allowed",
            }}
          >
            <img alt="" src={Microphone} className="me-2" /> Voice Message
          </button>
          <button
            className={`btn btn-cancel ms-3 recording_btn ${taskDetail?.userPermission?.can_i_edit
              ? "cursor_pointer"
              : "not-allowed-disable sprint-action"
              }`}
            onClick={
              taskDetail?.userPermission?.can_i_edit
                ? () => handleOpenAudioVideo("video")
                : null
            }
            style={{
              color: taskDetail?.userPermission?.can_i_edit ? "initial" : "gray",
              cursor: taskDetail?.userPermission?.can_i_edit
                ? "pointer"
                : "not-allowed",
            }}
          // disabled={!taskDetail?.userPermission?.can_i_edit}
          >
            <img alt="" src={Camera} className="me-2" /> Record Screen
          </button>
        </div>
        }
        <MediaFileCard
          setProgress={setProgress}
          setRecordedFiles={setMediaFiles}
          recordedFiles={mediaFiles}
          refreshMediaFiles={refreshMediaFiles}
        />
        {mediaFiles && mediaFiles?.length <= 0 ? (
          <p className="text-muted fs-6 mt-4 mb-4">No recorded message.</p>
        ) : null}
      </div>
      {isOpenAudioRecording && (
        <AudioRecorder
          setIsOpenAudioRecording={setIsOpenAudioRecording}
          isOpenAudioRecording={isOpenAudioRecording}
          mediaType={mediaType}
          setFileTitle={setFileTitle}
          fileTitle={fileTitle}
          recordingOrigin={recordingOrigin}
        />
      )}
    </>
  );
}

export default TaskMediaRecorder;
