import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

function MediaAccessAlertModal(props) {
    const { isOpenAccessAlert, handleClose, mediaType } = props;
    const message =
        mediaType === "audio"
            ? "We can’t proceed with your audio recording without permission to access your microphone. To enable audio recording,"
            : "We can’t proceed with screen recording without your permission to share your screen. To enable screen recording,";

    return (
        <Modal
            size="md"
            show={isOpenAccessAlert}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pt-5 pl-5 add-member_modal alert-modal_window"
            style={{ zIndex: "2000" }}
        >
            <div className="container p-0">
                <Modal.Body className="project-modal_body p-0">
                    <div className="fields">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="text-center px-3">
                                {/* Icon Section */}
                                <div className="bg-info mx-auto hw_50px rounded-circle d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faInfo} color="#fff" size="2x" />
                                </div>

                                {/* Title & Description */}
                                <h2 className="access-info mt-2">Permission Required</h2>
                                <p className="mt-2">{message} please follow these steps:</p>
                                <ul className="text-start mt-2 ms-auto">
                                    <li>Open your browser settings.</li>
                                    <li>
                                        Locate the appropriate permissions section for ({mediaType === "audio" ? "microphone access" : "screen sharing"}).
                                    </li>
                                    <li>Set it to "Allow".</li>
                                    <li>Refresh this page and try again.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="actions">
                            <button
                                className="btn btn-primary btn-sm mx-auto"
                                onClick={handleClose}
                            >
                                Got It!
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default MediaAccessAlertModal;
