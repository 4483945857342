import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { EMAIL_REGEX } from "../../../Utils/constants";
import GoogleLogo from "../../../assets/images/g.svg";
import { api } from "../../../customAxios";

import GitHubIcon from "@mui/icons-material/GitHub";
import { signupClicked } from "../../../Utils/analyticsHelper";
function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter valid email address")
      .matches(EMAIL_REGEX, "Please enter valid email address"),

    password: Yup.string()
      .required("Please enter your password")
      .min(6, "The password must be at least 6 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {},
  });

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      api.get("/auth/me").then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data.user));
        localStorage.setItem("enterpriceID", res.data.data?.enterprise?.id);
        console.log(res, "social login");
        localStorage.setItem(
          "permissions",
          JSON.stringify(res?.data?.data?.permissions)
        );
        navigate("/user/dashboard");
      });
    }
  }, [token]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    await api
      .post("/auth/login", data)
      .then((res) => {
        localStorage.setItem("enterpriceID", res.data.data?.enterprise?.id);
        localStorage.setItem("token", res.data.data.access_token);
        localStorage.setItem("user", JSON.stringify(res.data.data.user));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res?.data?.data?.permissions)
        );
        const redirectTo = queryParams.get("redirectTo");
        const destination = redirectTo
          ? decodeURIComponent(redirectTo)
          : "/user/dashboard";
        navigate(destination);
        setIsLoading(false);
        toast.success("You have successfully signed in");
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message === "authentication error"
            ? "The password is incorrect"
            : error.response?.data?.message
        );
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSocialLogin = (type) => {
    window.open(
      `${process.env.REACT_APP_API_URL?.replace(
        /\/api$/,
        ""
      )}/auth/redirect/${type}`,
      "_self"
    );
  };
  return (
    <>
      <div className="login__card">
        <h4 className="text-center mb-4">Sign In Your Account</h4>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <label className="form-label form-label__name">Email</label>
            <input
              type="text"
              className={`form-control form-control__input f-12 w-100  ${
                errors.email ? "is-invalid_boder" : ""
              }`}
              placeholder="Enter email address"
              {...register("email")}
            />
            <span className="danger-color error-msg">
              <span>{errors.email && errors.email.message}</span>
            </span>
          </div>
          <div className="password_input">
            <label className="form-label form-label__email">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className={`form-control form-control__input f-12 w-100 ${
                errors.password ? "is-invalid_boder" : ""
              }`}
              placeholder="Enter password"
              {...register("password")}
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon
                icon={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
              />
            </button>
            <span className="danger-color error-msg">
              <span>{errors.password && errors.password.message}</span>
            </span>
          </div>
          <span className="danger-color small-text-font">{errorMessage}</span>
          <p className="float-end custom-font-size-14 mb-1">
            <Link to="/forget">Forgot Password?</Link>
          </p>
          <button
            className={`btn btn-primary all-btn-padding mt-3 w-100 ${
              isLoading ? "auth-cliper-pd" : ""
            }`}
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? (
              <ClipLoader color="#fff" loading={isLoading} size={20} />
            ) : (
              "Sign In"
            )}
          </button>
          <p className=" custom-font-size-14 text-center mt-4">
            Don't have an account?{" "}
            <Link to="/signup" onClick={() => signupClicked()}>
              Sign Up
            </Link>
          </p>
          <div className="row">
            <div className="col-md-6 col-12">
              <div
                className="login__social-media text-center mt-5 cursor-pointer"
                onClick={() => handleSocialLogin("google")}
              >
                <img src={GoogleLogo} className="custom-image-margin" alt="" />
                <span className="custom-font-size-12 text-black hover__black">
                  Google
                </span>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div
                className="login__social-media text-center mt-5 cursor-pointer"
                onClick={() => handleSocialLogin("github")}
              >
                <GitHubIcon
                  sx={{ fontSize: 25, color: "black", marginRight: 2 }}
                />
                <span className="custom-font-size-12 text-black hover__black">
                  GitHub
                </span>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <div
                className="login__social-media text-center mt-4 cursor-pointer"
                onClick={() => handleSocialLogin("github")}
              >
                <GitHubIcon
                  sx={{ fontSize: 25, color: "black", marginRight: 2 }}
                />
                <span className="custom-font-size-12 text-black hover__black">
                  Github
                </span>
              </div>
            </div>
          </div> */}
        </form>
      </div>
    </>
  );
}

export default Login;
