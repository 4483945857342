import {
    faArrowRotateLeft,
    faEraser,
    faPalette,
    faPause,
    faPen,
    faPlay,
    faStop,
    faTrash,
    faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import CustomTooltip from "../comman/Task/CustomTooltip";

function MediaControls(props) {
    const {
        elapsedTime,
        handleOpenRestartConfirmation,
        stopRecording,
        paused,
        togglePause,
        handleOpenTrashConfirmation,
        isAudio,
        setStrokeColor,
        isDrawing,
        setIsDrawing,
        canvasRef,
    } = props;

    const colorInputRef = useRef(null);

    // Handle color selection & hide picker
    const handleColorChange = (e) => {
        setStrokeColor(e.target.value); // Set the selected color
        colorInputRef.current.blur(); // Remove focus to "close" the color picker
    };

    const handleClear = () => {
        if (canvasRef.current) {
            canvasRef.current.clearCanvas();
        }
    };

    const handleUndo = () => {
        if (canvasRef.current) {
            canvasRef.current.undo();
        }
    };
    return (
        <div
            className={`media-control-menu d-flex  p-3 br_10 align-items-center justify-content-center  ${isAudio ? "" : "control-menu-fixed"
                }`}
        >
            <CustomTooltip title={`Stop Recording`}>
                <button onClick={stopRecording} className="btn btn-danger fs-16">
                    <FontAwesomeIcon icon={faStop} />
                </button>
            </CustomTooltip>
            <CustomTooltip title={`${paused ? "Resume" : "Pause"} Recording`}>
                <button onClick={togglePause} className={`btn fs-16 btn-primary ms-3`}>
                    <FontAwesomeIcon icon={paused ? faPlay : faPause} />
                </button>
            </CustomTooltip>

            <span className="time-counter fs-16 fw-bold text-center ms-3">
                {Math.floor(elapsedTime / 60)}:
                {String(elapsedTime % 60).padStart(2, "0")}
            </span>
            <CustomTooltip title={`Restart Recording`}>
                <button
                    onClick={handleOpenRestartConfirmation}
                    className="btn btn-primary fs-16 ms-3"
                >
                    <FontAwesomeIcon icon={faArrowRotateLeft} />
                </button>
            </CustomTooltip>
            <CustomTooltip title={`Cancel Recording`}>
                <span onClick={handleOpenTrashConfirmation} className="btn fs-16 ms-3">
                    <FontAwesomeIcon icon={faTrash} />
                </span>
            </CustomTooltip>

            {!isAudio &&
                <> {/* Toggle Drawing */}
                    < CustomTooltip title={isDrawing ? "Disable Drawing" : "Enable Drawing"} >
                        <span onClick={() => setIsDrawing(!isDrawing)} className="btn fs-16 ms-3">
                            <FontAwesomeIcon icon={faPen} />
                        </span>
                    </CustomTooltip>


                    {/* 🎨 Animated Drawing Controls */}
                    <div className={`drawing-controls d-flex align-items-center gap-3 ${isDrawing ? "show ms-3" : "hide"}`}>
                        {/* Color Picker */}
                        <div className="position-relative d-inline-block">
                            <CustomTooltip title="Choose Color">
                                <span
                                    onClick={() => colorInputRef.current?.click()} // Open color picker on first click
                                    className="btn fs-16"
                                >
                                    <FontAwesomeIcon icon={faPalette} />
                                </span>
                            </CustomTooltip>

                            {/* Always in the DOM, but hidden */}
                            <input
                                ref={colorInputRef}
                                type="color"
                                onChange={handleColorChange}
                                className="drawing-color-picker pointer-none"
                            />
                        </div>

                        {/* Undo & Clear */}
                        <CustomTooltip title="Undo">
                            <span onClick={handleUndo} className="btn fs-16">
                                <FontAwesomeIcon icon={faUndo} />
                            </span>
                        </CustomTooltip>
                        <CustomTooltip title="Clear Drawing">
                            <span onClick={handleClear} className="btn fs-16">
                                <FontAwesomeIcon icon={faEraser} />
                            </span>
                        </CustomTooltip>
                    </div>
                </>
            }
        </div >
    );
}

export default MediaControls;
