import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Modal } from 'react-bootstrap'

function MediaConfirmationDialog(props) {
    const { isOpenConfirmModal, alertText, handleResumeRecording, confirmationType, handleConfirmation, handleCloseConfirmation } = props
    return (
        <Modal
            size="md"
            show={isOpenConfirmModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pt-5 pl-5 add-member_modal alert-modal_window"
            style={{ zIndex: "2000" }}
        >
            <div className="container p-0">
                <Modal.Body className="project-modal_body p-0">
                    <div className="fields">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 className="text-center fs_20 text-black">
                                    {alertText?.heading}
                                </h5>
                                <p className="text-center">{alertText?.subHeading}</p>
                            </div>
                        </div>
                        <div className="actions">
                            {handleCloseConfirmation ?
                                <button
                                    className="btn btn-cancel btn-sm d-flex align-items-center"
                                    onClick={handleCloseConfirmation}
                                >
                                    Cancel
                                </button>
                                :
                                <button
                                    className="btn btn-cancel btn-sm d-flex align-items-center"
                                    onClick={handleResumeRecording}
                                >
                                    <FontAwesomeIcon icon={faArrowRotateLeft} className="me-2" />
                                    Resume
                                </button>
                            }
                            <button
                                className={`btn  ${confirmationType === "restart" ? "btn-primary" : "btn-danger"
                                    }  btn-sm`}
                                onClick={
                                    handleConfirmation
                                }
                            >
                                {confirmationType === "restart" ? "Restart Recording" : confirmationType === "cancel" ? "Cancel Recording" : "Discard Recording"}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default MediaConfirmationDialog